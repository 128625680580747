import { IGetMobileAddonResponse } from '@vfit/consumer/data-access';
import { QueryClient } from 'react-query';
import { TSavedAddons } from './useAddonFlowMobile.models';

export const retrieveAvailableAddons = (getAddonsData: IGetMobileAddonResponse): string[] => {
  const availableList: string[] = [];
  const mobileAddons = getAddonsData?.bundledAddOnGroupConfiguration;
  if (mobileAddons) {
    mobileAddons.forEach((el) => {
      el?.bundledAddOnConfiguration?.forEach((bAOC) => {
        availableList.push(bAOC?.offeringId);
      });
    });
  }
  return availableList;
};

export const getReferenceId = (addonMecId: string, data: any) => {
  return (
    data?.bundledAddOnConfiguration?.find((el: any) => el.offeringId === addonMecId)
      ?.addOnOfferingConfiguration[0]?.productSpecificationConfiguration?.referenceId || null
  );
};

export const saveAddonOnQueryClient = (
  queryClient: QueryClient,
  addonId: string,
  referenceId: string
) => {
  const savedAddons =
    (queryClient.getQueryData('SavedAddons') as Record<string, TSavedAddons>) || {};
  queryClient.setQueryData('SavedAddons', {
    ...savedAddons,
    [addonId]: {
      referenceId,
    },
  });
};

export const removeAddonFromQueryClient = (queryClient: QueryClient, addonId: string) => {
  const savedAddons =
    (queryClient.getQueryData('SavedAddons') as Record<string, TSavedAddons>) || {};

  if (savedAddons[addonId]) {
    const { [addonId]: _, ...updatedAddons } = savedAddons;
    queryClient.setQueryData('SavedAddons', updatedAddons);
  }
};

export const getReferenceIdFromQueryClient = (
  queryClient: QueryClient,
  addonId: string
): string => {
  const savedAddons = queryClient.getQueryData('SavedAddons') as Record<string, TSavedAddons>;
  return savedAddons?.[addonId]?.referenceId || '';
};
