import { useEffect } from 'react';
import {
  errorMock,
  getCurrentUserType,
  getHubPath,
  ICampaignResponse,
  ICampaignErrorType,
  IUserType,
  useCampaignAccept,
  useCampaignsInfoMobile,
  useGetShoppingCart,
  usePage,
} from '@vfit/consumer/data-access';
import { updateProductPrice } from '../../../ShoppingCart/shoppingCart.utils';
import { useCheckout } from '../../../iBuyMobile.context';

export const useForceCampaignFlow = () => {
  const {
    product,
    campaign,
    isStartCampaign,
    isStartCampaignAccept,
    setIsLoadingCart,
    setIsStartCampaign,
    setIsStartAcceptCampaign,
    setCampaign,
    setProduct,
  } = useCheckout();
  const { products: allProducts, product: pageProduct } = usePage();
  const isCampaignProduct = product?.isFamilyPlusProduct || product?.forceCampaign;
  // if product is familyplus from hub the familyplus id is the offerId else the familyPlusId in poduct configuration
  const campaignId = isCampaignProduct ? product?.offerId : product?.familyPlusId;
  const campaignProduct = allProducts?.find((el) => el.offerId.toString() == campaignId);
  const currentUserType = getCurrentUserType();
  const isProspectOrInactive =
    currentUserType === IUserType.PROSPECT_USER || currentUserType === IUserType.NEXT_USER_INACTIVE;
  const {
    data: campaignsData,
    isSuccess: isSuccessCampaigns,
    isError: isErrorCampaigns,
    isLoading: isLoadingCampaigns,
    refetch: refetchCampaignsInfoMobile,
  } = useCampaignsInfoMobile({ enabled: false });
  const {
    mutate: mutateAcceptCampaign,
    data: acceptCampaignData,
    isSuccess: isSuccessAcceptCampaign,
    isLoading: isLoadingAcceptCampaign,
    isError: isErrorAcceptCampaign,
  } = useCampaignAccept();
  const {
    isSuccess: isSuccessGetShoppingCart,
    isLoading: isLoadingGetShoppingCart,
    isError: isErrorGetShoppingCart,
    error: errorGetShoppingCart,
    refetch: getShoppingCartRefetch,
  } = useGetShoppingCart(campaign?.accept?.acceptId || '', { enabled: false });

  // region UPDATE CART

  useEffect(() => {
    if (isLoadingGetShoppingCart && isSuccessAcceptCampaign) {
      if (isCampaignProduct) setIsLoadingCart(true);
      setCampaign({
        ...campaign,
        updateCart: {
          ...campaign.accept,
          isLoading: true,
          isSuccess: false,
          isError: false,
        },
      });
    } else if (isErrorGetShoppingCart && isSuccessAcceptCampaign) {
      if (isCampaignProduct) setIsLoadingCart(false);
      setCampaign({
        ...campaign,
        updateCart: {
          ...campaign.accept,
          isLoading: false,
          isSuccess: false,
          isError: true,
          error: errorMock(
            'shoppingCart',
            errorGetShoppingCart,
            undefined,
            undefined,
            'Error get shopping cart for family plus',
            false,
            {
              familyPlusAcceptId: campaign?.accept?.acceptId,
            }
          ),
          errorType: ICampaignErrorType.GET_SHOPPING_CART,
        },
      });
    } else if (isSuccessGetShoppingCart && isSuccessAcceptCampaign) {
      // if not is familyplus from hub
      // switch product
      if (!isCampaignProduct) {
        const hub = pageProduct ? getHubPath(pageProduct) : '';
        if (campaignProduct) {
          localStorage.setItem('tProd_backUp', JSON.stringify(product));
          setProduct({
            ...product,
            ...campaignProduct,
            ...(hub && { hubPath: hub }),
            ...updateProductPrice(campaignProduct),
            shoppingCartUrl: window.location.href,
          });
        }
      } else {
        // else update family plus price
        setProduct({
          ...product,
          ...updateProductPrice(product),
        });
        setIsLoadingCart(false);
      }
      setCampaign({
        ...campaign,
        updateCart: {
          ...campaign.accept,
          isLoading: false,
          isSuccess: true,
          isError: false,
          errorType: undefined,
        },
      });
    }
  }, [isLoadingGetShoppingCart, isErrorGetShoppingCart, isSuccessGetShoppingCart]);

  // endregion

  // region ACCEPT

  useEffect(() => {
    if (campaign?.accept?.acceptId && !isSuccessGetShoppingCart && !isLoadingGetShoppingCart)
      getShoppingCartRefetch();
  }, [campaign]);

  useEffect(() => {
    if (isLoadingAcceptCampaign) {
      setCampaign({
        ...campaign,
        accept: {
          ...campaign.accept,
          isLoading: true,
          isSuccess: false,
          isAccepted: false,
          isError: false,
        },
      });
    } else if (isErrorAcceptCampaign) {
      setCampaign({
        ...campaign,
        accept: {
          ...campaign.accept,
          isLoading: false,
          isSuccess: false,
          isAccepted: false,
          isError: true,
          errorType: ICampaignErrorType.ACCEPT,
        },
      });
    } else if (isSuccessAcceptCampaign) {
      setCampaign({
        ...campaign,
        accept: {
          ...campaign.accept,
          isLoading: false,
          isSuccess: true,
          isAccepted: true,
          isError: false,
          acceptId: acceptCampaignData?.orderId,
        },
        updateCart: {
          ...campaign.updateCart,
          isLoading: true,
        },
      });
    }
  }, [isLoadingAcceptCampaign, isErrorAcceptCampaign, isSuccessAcceptCampaign]);

  useEffect(() => {
    if (isStartCampaignAccept && !campaign?.accept?.isAccepted) {
      setIsStartAcceptCampaign(false);
      mutateAcceptCampaign({
        campaignId: campaign.campaign?.campaignId || '',
        sessionId: campaign.campaign?.sessionId || '',
      });
    }
  }, [isStartCampaignAccept]);

  // endregion

  // region CAMPAIGNS

  const checkHasCampaign = () => {
    const foundedCampaign = campaignsData?.find(
      (item: ICampaignResponse) =>
        item?.productOfferId?.toString() === campaignProduct?.offerId?.toString()
    );
    if (product?.isFamilyPlusProduct || product?.forceCampaign) {
      setCampaign({
        ...campaign,
        campaign: {
          ...campaign.campaign,
          isLoading: false,
          isError: false,
          isSuccess: true,
          isEligible: !!foundedCampaign,
          campaignId: foundedCampaign?.id?.toString() || '',
          sessionId: foundedCampaign?.sessionId?.toString() || '',
          ...(!!foundedCampaign && { campaignProduct }),
        },
      });
    }
  };

  useEffect(() => {
    if (isLoadingCampaigns) {
      setCampaign({
        ...campaign,
        campaign: {
          ...campaign.campaign,
          isSuccess: false,
          isError: false,
          isEligible: false,
          isLoading: true,
        },
      });
    } else if (isErrorCampaigns) {
      setCampaign({
        ...campaign,
        campaign: {
          ...campaign.campaign,
          isLoading: false,
          isSuccess: false,
          isEligible: false,
          isError: true,
          errorType: ICampaignErrorType.CAMPAIGNS,
        },
      });
    } else if (isSuccessCampaigns) {
      checkHasCampaign();
    }
  }, [isLoadingCampaigns, isErrorCampaigns, isSuccessCampaigns]);

  useEffect(() => {
    if (!isProspectOrInactive && isStartCampaign && !isSuccessCampaigns) {
      setIsStartCampaign(false);
      refetchCampaignsInfoMobile();
    }
  }, [isStartCampaign]);

  // endregion

  return null;
};
