import {IAddonDetailsCMS, IDetailsPageCMS} from "@vfit/consumer/data-access";
import {purify} from "@vfit/shared/themes";
import {IDetailsPageLabels} from "./productDetailExtended.models";

export const evaluateAddonDetails = () => {
  const summary = (addon? : IAddonDetailsCMS) => addon?.addondetails?.summary || {}
  const common = (addon? : IAddonDetailsCMS) => addon?.addondetails?.common || {}
  const detailPopup = (addon? : IAddonDetailsCMS) => addon?.addondetails?.detailPopup || {}

  const addonCard = (addon? : IAddonDetailsCMS) => addon?.addondetails?.card || {}

  return ({
    summary,
    common,
    detailPopup,
    addonCard
  })

}


export const evaluateLabels = (data : IDetailsPageCMS) : IDetailsPageLabels => {
  const [priceNoticePurified] = purify([data?.detailspage?.price?.priceNotice])
  return ({
    priceSectionTitle: data?.detailspage?.price?.title || '',
    recurrencyLabel: data?.detailspage?.price?.recurrencyLabel || '',
    immediateLabel: data?.detailspage?.price?.immediateLabel || '',
    viewDetailsLabel: data?.detailspage?.common?.viewDetails || '',
    goBackLabel: data?.detailspage?.common?.goBackLabel || '',
    topPriceLabel: data?.detailspage?.common?.productTopPriceLabel || '',
    firstInvoiceLabel: data?.detailspage?.price?.firstInvoiceLabel || '',
    priceNoticeHtml: priceNoticePurified || ''
  })
}
