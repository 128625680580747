import {
  checkBypassApiCallFromSIA,
  errorMock,
  useDeliveryBaseV1,
} from '@vfit/consumer/data-access';
import { useEffect, useState } from 'react';
import { IIdentificationDetails } from '@vfit/shared/models';
import { INextError, ITrackError } from '@vfit/shared/data-access';
import { IUseDelivery, IUseDeliveryProps } from './useDeliveryBaseFlow.models';

export const useDeliveryBaseFlow = ({ enabled, isEsim }: IUseDeliveryProps): IUseDelivery => {
  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [error, setError] = useState<ITrackError | undefined>(undefined);
  const [deliveryOptions, setDeliveryOptions] = useState<[]>([]);
  const [identificationDetails, setIdentificationDetails] = useState<
    IIdentificationDetails | IIdentificationDetails[]
  >([]);

  const enabler = !checkBypassApiCallFromSIA() && !isError && enabled;

  const {
    data: deliveryBaseData,
    error: deliveryBaseError,
    isSuccess: deliveryBaseIsSuccess,
    isLoading: deliveryBaseIsLoading,
    isError: deliveryBaseIsError,
  } = useDeliveryBaseV1(isEsim || false, {
    enabled: enabler,
    keyDependency: ['deliveryDetailsIsBaseMobile'],
  });

  useEffect(() => {
    if (deliveryBaseIsLoading) {
      setIsSuccess(false);
      setIsLoading(true);
      setIsError(false);
    } else if (deliveryBaseIsSuccess && !isSuccess && !checkBypassApiCallFromSIA()) {
      setDeliveryOptions(
        Array.isArray(deliveryBaseData)
          ? deliveryBaseData?.[0]?.deliveryDetails || []
          : deliveryBaseData?.deliveryDetails || []
      );
      setIdentificationDetails(
        Array.isArray(deliveryBaseData)
          ? deliveryBaseData?.[0]?.identificationDetails || {}
          : deliveryBaseData?.identificationDetails || {}
      );
      setIsLoading(false);
      setIsSuccess(true);
    }
  }, [deliveryBaseIsSuccess, deliveryBaseIsLoading, deliveryOptions]);

  useEffect(() => {
    if (deliveryBaseIsError) {
      setIsLoading(false);
      setIsError(true);
      setError(errorMock('deliveryBase', deliveryBaseError as INextError));
      setErrorMessage(deliveryBaseData?.errorMessage);
    }
  }, [deliveryBaseIsError, deliveryBaseData]);

  return {
    isSuccess,
    isLoading,
    isError,
    errorMessage,
    deliveryOptions,
    identificationDetails,
    error,
  };
};
