import {Chip, Price, Svg} from "@vfit/shared/atoms";
import {trackLink} from "@vfit/shared/data-access";
import {IProductBlock} from "../../productDetailExtended.models";
import * as S from "../../productDetailExtended.style";
import {evaluateAddonDetails} from "../../productDetailExtended.utils";

export const ProductBlock = ({product, addonList, setDetailMode} : IProductBlock) => {
  const {summary} = evaluateAddonDetails();
  const handleClick = () => {
    trackLink('Scopri tutti i dettagli');
    setDetailMode(true);
  }
  return (
    <S.Block>
      <S.SubBlock>
        <S.BigText>{product.title || product.category}</S.BigText>
        {product?.voucherPromoLabel && (
          <div style={{width: "auto"}}>
            <Chip margin={[0]}>{product?.voucherPromoLabel}</Chip>
          </div>
        )}
        <S.DetailsContainer>
          <S.BoldLabel>Costo ricorrente</S.BoldLabel>
          <Price
            amount={product?.cartPrices?.offerPrice.amount.toString() || ''}
            recurrence={product.recurrence}
            description={product.priceActivation}
          />
          {addonList && addonList.map((addon) => (
            summary(addon)?.benefit &&
            <S.BulletWithIcon>
              <Svg name='tickCircleCyan' height={22} width={22} />
              <S.BulletText>{summary(addon)?.benefit}</S.BulletText>
            </S.BulletWithIcon>
          ))}
        </S.DetailsContainer>

        <S.DetailsCta onClick={handleClick} >Scopri tutti i dettagli</S.DetailsCta>
      </S.SubBlock>

    </S.Block>
  )
}
