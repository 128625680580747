import { useEffect, useState } from 'react';
import {
  checkIsGoBackSIA,
  errorMock,
  IShoppingCartResponse,
  useNotify,
  useUpdateProductCharacteristic,
  useUpdateProductCharacteristicLight,
} from '@vfit/consumer/data-access';
import { useQueryClient } from 'react-query';
import { ITrackError, LoggerInstance } from '@vfit/shared/data-access';
import { getFromLocalStorageByKey } from '@vfit/shared/data-access';
import { IAPIError, ITermsFlow } from './useTermsFlow.models';
import { useCheckout } from '../../../iBuyMobile.context';

export const useTermsFlow = (
  termsAndConditions: any,
  isSiaError?: boolean,
  postponeNotify = false,
  enabled = true
): ITermsFlow => {
  const { product } = useCheckout();
  const queryClient = useQueryClient();
  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);
  const [error, setError] = useState<ITrackError | undefined>(undefined);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [optionToReconsiderState, setOptionToReconsiderState] = useState<string>(
    termsAndConditions?.optionToReconsider
  );

  const updatedProductCharacteristic_2: any | IAPIError | undefined = queryClient.getQueryData(
    'updateProductCharacteristic_2'
  );
  const updatedProductCharacteristicLight: any | IAPIError | undefined = queryClient.getQueryData(
    'updateProductCharacteristicLight'
  );

  /**
   * get notify data from localstorage or query
   */
  const notifyFromStorage = getFromLocalStorageByKey('notify');
  const notifyFromQuery = queryClient.getQueryData('notify');
  const notify = notifyFromQuery || notifyFromStorage;

  const useUpdateProductCharacteristicMutation = useUpdateProductCharacteristic();

  const useUpdateProductCharacteristicLightMutation = useUpdateProductCharacteristicLight();

  const notifyMutation = useNotify();

  useEffect(() => {
    if (
      (termsAndConditions?.consensus || termsAndConditions?.preConsensus) &&
      termsAndConditions?.optionToReconsider !== '' &&
      (!useUpdateProductCharacteristicMutation.isSuccess ||
        termsAndConditions?.optionToReconsider !== optionToReconsiderState)
    ) {
      setIsSuccess(false);
      setIsLoading(true);
    }
  }, [
    termsAndConditions?.consensus,
    termsAndConditions?.preConsensus,
    termsAndConditions?.optionToReconsider,
  ]);

  useEffect(() => {
    if (isLoading && enabled && !checkIsGoBackSIA()) {
      const shoppingCart: IShoppingCartResponse = getFromLocalStorageByKey('shoppingCart');
      const cartId = shoppingCart?.id;
      if (cartId)
        useUpdateProductCharacteristicMutation.mutate(termsAndConditions.optionToReconsider);
    }
  }, [isLoading, enabled]);

  useEffect(() => {
    const isEnabledNotify =
      enabled &&
      !product?.isTpoOffer &&
      !checkIsGoBackSIA() &&
      !notifyMutation.isLoading &&
      !notifyMutation.isError &&
      !notifyMutation.isSuccess &&
      (termsAndConditions?.consensus || termsAndConditions?.preConsensus) &&
      !isSiaError;
    if (isEnabledNotify) {
      const notifyData = localStorage.getItem('notify');
      if (notifyData !== '1' && !postponeNotify)
        notifyMutation.mutate(termsAndConditions?.consensus || termsAndConditions?.preConsensus);
    }
  }, [
    enabled,
    notifyMutation.isSuccess,
    notifyMutation.isLoading,
    notifyMutation.isError,
    termsAndConditions?.consensus,
    termsAndConditions?.preConsensus,
    termsAndConditions?.optionToReconsider,
    useUpdateProductCharacteristicMutation.isSuccess,
  ]);

  useEffect(() => {
    if (updatedProductCharacteristic_2?.error) {
      setIsLoading(false);
      setIsError(true);
      setErrorMessage(updatedProductCharacteristic_2.errorMessage);
      setError(errorMock('notify', updatedProductCharacteristic_2?.error));
    }

    if (notify?.error) {
      setIsLoading(false);
      setIsError(true);
      setErrorMessage(notify?.errorMessage);
      setError(errorMock('notify', notify?.error));
    }
    LoggerInstance.debug('useTermsFlow optionToReconsiderState BEFORE : ', optionToReconsiderState);
    LoggerInstance.debug(
      'useTermsFlow termsAndConditions.optionToReconsider BEFORE : ',
      termsAndConditions?.optionToReconsider
    );
    if (
      !checkIsGoBackSIA() &&
      isLoading &&
      enabled &&
      updatedProductCharacteristic_2 &&
      useUpdateProductCharacteristicMutation.isSuccess &&
      !updatedProductCharacteristic_2?.error &&
      termsAndConditions?.optionToReconsider &&
      (termsAndConditions?.consensus || termsAndConditions?.preConsensus) &&
      ((!useUpdateProductCharacteristicLightMutation.isSuccess &&
        !useUpdateProductCharacteristicLightMutation.isLoading) ||
        optionToReconsiderState != termsAndConditions?.optionToReconsider)
    ) {
      LoggerInstance.debug(
        'useTermsFlow optionToReconsiderState INSIDE : ',
        optionToReconsiderState
      );
      LoggerInstance.debug(
        'useTermsFlow termsAndConditions.optionToReconsider INSIDE : ',
        termsAndConditions?.optionToReconsider
      );
      useUpdateProductCharacteristicLightMutation.mutate(termsAndConditions?.optionToReconsider);
      setOptionToReconsiderState(termsAndConditions?.optionToReconsider);
    }
  }, [
    enabled,
    updatedProductCharacteristicLight,
    updatedProductCharacteristic_2,
    termsAndConditions?.consensus,
    termsAndConditions?.preConsensus,
    termsAndConditions?.optionToReconsider,
    useUpdateProductCharacteristicMutation.isSuccess,
  ]);

  useEffect(() => {
    if (useUpdateProductCharacteristicLightMutation.isSuccess) {
      setIsLoading(false);
      setIsSuccess(true);
    }
  }, [
    updatedProductCharacteristicLight,
    useUpdateProductCharacteristicLightMutation.isSuccess,
    useUpdateProductCharacteristicLightMutation.isError,
  ]);

  return { isSuccess, isLoading, isError, error, errorMessage };
};
