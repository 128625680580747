interface AddonOfferingConfiguration {
  offering: {
    id: string;
    name: string;
  };
}

interface BundledAddonConfiguration {
  bundledAddOnConfiguration: BundledAddonConfiguration[];
  addonOfferingConfiguration: AddonOfferingConfiguration[];
  offeringId: string;
}

interface BundledAddOnGroupConfiguration {
  bundledAddOnConfiguration: BundledAddonConfiguration[];
}

interface MobileOfferingConfiguration {
  id: string;
  offeringType: IOfferingType;
  bundledAddOnConfiguration: BundledAddonConfiguration[];
  bundledAddOnGroupConfiguration: BundledAddonConfiguration[];
}

interface BundledDataSimOfferingConfiguration {
  mobileOfferingConfiguration: MobileOfferingConfiguration[];
}

interface MobileConfiguration {
  bundledAddOnGroupConfiguration: MobileOfferingConfiguration[];
}

interface InternetOfferingConfiguration {
  id: string;
  offeringType: IOfferingType;
  bundledAddOnConfiguration: BundledAddonConfiguration[];
  bundledAddOnGroupConfiguration?: BundledAddOnGroupConfiguration[];
}

interface BundledInternetOfferingConfiguration {
  internetOfferingConfiguration: InternetOfferingConfiguration[];
}

interface FixedVoiceOfferingConfiguration {
  id: string;
  offeringType: IOfferingType;
  bundledAddOnConfiguration: BundledAddonConfiguration[];
  bundledAddOnGroupConfiguration?: BundledAddOnGroupConfiguration[];
}

interface BundledFixedVoiceOfferingConfiguration {
  fixedVoiceOfferingConfiguration: FixedVoiceOfferingConfiguration[];
}

interface MultiPlayResponseForAddons {
  bundledFixedVoiceOfferingConfiguration: BundledFixedVoiceOfferingConfiguration[];
  bundledInternetOfferingConfiguration: BundledInternetOfferingConfiguration[];
  bundledDataSimOfferingConfiguration: BundledDataSimOfferingConfiguration[];
}

export enum IOfferingType {
  FIXED_VOICE = 'fixedVoiceOffering',
  SIM_DATA = 'mobileOffering',
  INTERNET = 'internetOffering',
  MOBILE = 'mobile',
}

export type IGetAddonResponse = Partial<MultiPlayResponseForAddons>;

export type IGetMobileAddonResponse = Partial<MobileConfiguration>;
