import * as S from "../../productDetailExtended.style";
import {IPriceBlockProps} from "../../productDetailExtended.models";

export const PriceBlock = ({product, labels} : IPriceBlockProps) => {
  const {cartPrices} = product;
  const discountDuration = cartPrices?.offerPrice.recurringPeriod;
  const {priceSectionTitle, recurrencyLabel, immediateLabel, firstInvoiceLabel, priceNoticeHtml} = labels;
  const showPriceNotice = Boolean(discountDuration && discountDuration > 0);
  const showFirstInvoice = cartPrices?.firstInvoicePrice?.amount !== 0
  const handlePlaceholders = (text : string) => {
    let outputText = text;
    if(discountDuration && discountDuration > 0){
      outputText = text
        .replace("$PERIOD", `${discountDuration.toString()} mesi`)
        .replace('$ORIGINALPRICE', cartPrices?.recurringPrice?.oldAmount?.toString() || '')
    }
    return outputText;
  }
  return(
    <S.GrayBlock>
      <S.SubBlock>
        <S.BigText>{priceSectionTitle}</S.BigText>
        <S.DetailsContainer>
          <S.BoldLabel>{immediateLabel}</S.BoldLabel>
          <S.Price variant>{cartPrices?.immediatePrice?.amount}€</S.Price>
        </S.DetailsContainer>

        {showFirstInvoice &&
          <S.DetailsContainer>
            <S.BoldLabel>{firstInvoiceLabel}</S.BoldLabel>
            <S.Price variant>{cartPrices?.firstInvoicePrice?.amount}€</S.Price>
          </S.DetailsContainer>
        }

        <S.DetailsContainer>
          <S.BoldLabel>{recurrencyLabel}</S.BoldLabel>
          <S.Price variant>{cartPrices?.recurringPrice?.amount}€/{product.recurrence}</S.Price>
          {showPriceNotice &&
            // eslint-disable-next-line react/no-danger
            <div dangerouslySetInnerHTML={{
              __html: handlePlaceholders(priceNoticeHtml),
            }} />
          }
        </S.DetailsContainer>

      </S.SubBlock>
  </S.GrayBlock>
  )
};

