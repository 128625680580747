import {API, getFromLocalStorageByKey, INextError, LoggerInstance, nextClient} from "@vfit/shared/data-access";
import {EndpointsEnum, errorMock, IOfferingType, IUseAddonMutation} from "@vfit/consumer/data-access";
import {useMutation, useQueryClient} from "react-query";
import {buildAdditionPayload, buildRemovalPayload} from "./postAddon.utils";

const mobileAddonService = (
  endpoint: EndpointsEnum,
  offeringId: string,
  payload: unknown
) : Promise<unknown> =>
  nextClient.post(`${API.MOBILE_OFFERING_CONFIGURATION}/${offeringId}/${endpoint}`, payload);

export const useAddMobileAddon = () => {
  const queryClient = useQueryClient();
  const shoppingCart = getFromLocalStorageByKey('shoppingCart');
  const cartItem = shoppingCart?.cartItem?.[0].offering.id;
  return useMutation(
    'addMobileAddon',
    ({cartId, addonId} : Omit<IUseAddonMutation, 'type'>) => {
      const payload = buildAdditionPayload(cartId, addonId, IOfferingType.SIM_DATA);
      const mobileId = cartItem?.mobile?.[0]?.id;
      return mobileAddonService(EndpointsEnum.ADD_MOBILE, mobileId, payload);
    },
    {
      onSuccess: (data: unknown) => {
        queryClient.setQueryData('addMobileAddon', data);
      },
      onError: (error: INextError) => {
        LoggerInstance.error('ERROR - addMobileAddon', error);
        queryClient.setQueryData('addMobileAddon', errorMock('addAddon', error));

      }
    }
  )
};

export const useRemoveMobileAddon = () => {
  const queryClient = useQueryClient();
  const shoppingCart = getFromLocalStorageByKey('shoppingCart');
  const cartItem = shoppingCart?.cartItem?.[0].offering.id;
  return useMutation(
    'removeMobileAddon',
    ({cartId, addonId} : Omit<IUseAddonMutation, 'type'>) => {
      const payload = buildRemovalPayload(cartId, addonId, IOfferingType.SIM_DATA);
      const mobileId = cartItem?.mobile?.[0]?.id;
      return mobileAddonService(EndpointsEnum.REMOVE_MOBILE, mobileId, payload);
    },
    {
      onSuccess: (data: unknown) => {
        queryClient.setQueryData('removeMobileAddon', data);
      },
      onError: (error: INextError) => {
        LoggerInstance.error('ERROR - removeMobileAddon', error);
        queryClient.setQueryData('removeMobileAddon', errorMock('addAddon', error));

      }
    }
  )
};
