import { Button, Svg } from '@vfit/shared/atoms';
import {trackView} from "@vfit/shared/data-access";
import { useModalConfirmRemoveAddOn } from './ModalConfirmRemoveAddOn.hook';
import { IModalConfirmRemoveAddOn } from './ModalConfirmRemoveAddOn.models';
import * as S from './ModalConfirmRemoveAddOn.style';

const ModalConfirmRemoveAddOn = ({
  setIsOpenModalSureDeleteAddOn,
  handleRemoveAction,
  addonCmsId,
}: IModalConfirmRemoveAddOn) => {
  trackView({
    event_name: 'view',
    event_label_track: 'addons:remove confirm',
    journey_type: 'journey',
    journey_name: 'checkout',
    page_type: 'popup'
  })

  //
  const { handleRemoveAddOn, handleClosePopup, removePopup } = useModalConfirmRemoveAddOn({
    setIsOpenModalSureDeleteAddOn,
    handleRemoveAction,
    addonCmsId,
  });

  return (
    <S.ConfirmDeleteAddOn>
      <S.Content>
        <Svg height={64} width={64} name="warningBig" />
        <S.Title dangerouslySetInnerHTML={{ __html: removePopup.title }} />
        <S.Desription dangerouslySetInnerHTML={{ __html: removePopup.description }} />
      </S.Content>
      <S.ButtonsBox>
        <Button variant="primary" onClick={handleClosePopup}>
          {removePopup.continueCtaLabel}
        </Button>
        <Button variant="secondary" onClick={handleRemoveAddOn}>
          {removePopup.removeCtaLabel}
        </Button>
      </S.ButtonsBox>
    </S.ConfirmDeleteAddOn>
  );
};

export { ModalConfirmRemoveAddOn };
