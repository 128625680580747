import styled from "styled-components";
import {breakpoints, colors, fonts, pxToCssFont} from "@vfit/shared/themes";

export const BlocksContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 24px 0;
`;

export const Block = styled.div`
  display:flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  border: #bebebe 1px solid;
  border-radius: 16px;
  padding: 16px;
`;

export const GrayBlock = styled(Block)`
  background-color: #f2f2f2;
`;

export const SubBlock = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-self: stretch;
`;

export const SeparationLine = styled.div`
  width: 295px;
  height: 1px;
  background-color: #bebebe;
  margin: 24px 0;
`;

export const DetailsCta = styled.a`
  font-family: ${fonts.regular};
  ${pxToCssFont(16,22)}
  font-weight: 700;
  text-align: left;
  text-decoration-line: underline;
  text-decoration-style: solid;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #262626;
  cursor: pointer;
`;

export const BigText = styled.div`
  font-family: ${fonts.exbold};
  ${pxToCssFont(24,32)};
  @media(min-width: ${breakpoints.tablet}){
    ${pxToCssFont(30,38)};
  };
  @media(min-width: ${breakpoints.desktop}){
    ${pxToCssFont(36,45)};
  };
`;

export const BoldLabel = styled.div`
  ${pxToCssFont(18,24)};
  font-weight: 700;
`;

export const Price = styled.div<{ variant? : boolean }>`
  font-family: ${fonts.vodafone};
  ${pxToCssFont(24,32)};
  font-weight: 800;
  color: ${props => props.variant ? '#00697C' : '#262626'}
`;

export const DetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 4px;
  align-self: stretch;
`;

export const AddonDescription = styled.div`
  font-family: ${fonts.vodafone};
  ${pxToCssFont(16,22)};
  color: ${colors.$7e7e7e};
  p{ margin: 0};
`;

export const HeaderWithIcon = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const BulletWithIcon = styled.div`
  display: flex;
  gap: 8px;
`;

export const BulletText = styled.div`
  font-family: ${fonts.vodafone};
  ${pxToCssFont(16,22)};
  font-weight: 700;
  color: #00697C; // todo : da aggiungere
`;

export const Breadcrumb = styled.div`
  display: flex;
  margin-top: 20px;
  margin-bottom: -12px;
  align-items: center;
  gap: 10px;
  cursor: pointer;
`;

export const GoBackText = styled.div`
  ${pxToCssFont(14, 18)};
  font-weight: 700;
  align-self: flex-end;
`

export const CtaContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-self: center;
  width: 100%;
  gap: 16px;
`;
