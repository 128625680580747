import {IOfferingType} from "@vfit/consumer/data-access";

const retrieveCustomPayloadData = (offeringType: IOfferingType) =>{
  let keyType = '';
  let keyTypeRemoval= ''
  let levelOfData = '';
  switch(offeringType){
    case IOfferingType.FIXED_VOICE:{
      keyType = 'fixedVoiceAddOnOfferingConfiguration';
      keyTypeRemoval = 'fixedVoiceAddOnOfferingConfigurationId';
      levelOfData = 'fixedVoiceOfferingConfiguration';
      break;
    }
    case IOfferingType.INTERNET:{
      keyType = 'internetAddOnOfferingConfiguration';
      keyTypeRemoval = 'internetAddOnOfferingConfigurationId';
      levelOfData = 'internetOfferingConfiguration';
      break;
    }
    case IOfferingType.SIM_DATA:{
      keyType = 'mobileAddOnOfferingConfiguration';
      keyTypeRemoval = 'mobileAddOnOfferingConfigurationId';
      levelOfData = 'mobileOfferingConfiguration';
      break;
    }
  }
  return {keyType, keyTypeRemoval, levelOfData}
}

export const buildAdditionPayload = (cartId: string, addonId: string, offeringType : IOfferingType) => {
  const {keyType, levelOfData} = retrieveCustomPayloadData(offeringType)
  return ({
    qualificationCriteria: {
      orderingActivity: "newSubscription"
    },
    context: `shoppingCart.Id==${cartId}`,
    configurationType: "fullConfigurable",
    levelOfData: `bundledAddOnConfiguration,bundledAddOnGroupConfiguration,price,${levelOfData}`,
    runValidation: "true",
    [keyType]: {
      offering: {
        id: addonId
      }
    }
  });
}

export const buildRemovalPayload = (cartId: string, addonId: string, offeringType: IOfferingType) => {
  const {keyTypeRemoval, levelOfData} = retrieveCustomPayloadData(offeringType)

  return ({
    qualificationCriteria: {
      "orderingActivity": "newSubscription"
    },
    context: `shoppingCart.Id==${cartId}`,
    levelOfData: `bundledAddOnConfiguration,bundledAddOnGroupConfiguration,price,${levelOfData}`,
    runValidation: "true",
    [keyTypeRemoval]: addonId
  });
}
