import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useQueryClient } from 'react-query';
import {
  LoggerInstance,
  resetData,
  errorManager,
  ErrorService,
  ITrackError,
  retrieveBasePageName,
  tracking,
} from '@vfit/shared/data-access';
import {
  ALL_EVENTS,
  checkIsGoBackSIA,
  CONSUMER_CMS_SHOPPING_CART,
  ErrorCodes,
  FLOW_TYPE,
  GetCustomerResponse,
  getLastPaymentMethod,
  IGlobalConfigCMS,
  IGenericErrorCMS,
  IPayments,
  IProduct,
  IS_AUTOMATIC_RECHARGE_SELECTED,
  LOGGED_USER_KEYS,
  PAYMENT_ERROR_MESSAGE,
  PAYMENT_ERROR_TITLE,
  PAYMENT_SHOW_CTC,
  productSpecificError,
  SELECTED_PAYMENT,
  useCmsConfig,
  checkIsApp,
  getParamsInUrl,
  IUserType,
  getCurrentUserType,
  ICommonLabelsInStepper,
  organizeCommonLabelsInStepper,
  IDetailsPageCMS,
} from '@vfit/consumer/data-access';
import { removeCurrency, usePayMeanData } from '@vfit/consumer/hooks';
import {
  EMethodCode,
  IAddressDataObject,
  ICMSApiError,
  ICMSError,
  ICountryObject,
  IPayMeanServiceResponse,
  ISIAFlowType,
  IStepCard,
  IStepperUtils,
  IValidAddressList,
} from '@vfit/shared/models';
import { LoadingSpinner } from '@vfit/shared/atoms';
import { API, AppMobile, getFromLocalStorageByKey, openPopup } from '@vfit/shared/data-access';
import { ProductDetail, ISupportModule, StepperStateCards } from '@vfit/shared/components';
import { CheckoutModalContainer, LoadingPage, OverlayGlobalStyle } from './checkout.style';
import { ICheckoutModalChildrenProps, ICheckoutSIA } from './checkout.models';
import {
  addRemoveVisibilityCards,
  deSeriealizeAllSteps,
  getActiveSlideFromStorage,
  getUserInfo,
  getBillingForLoggedOrActive,
  getShippingForLoggedOrActive,
  getSlideIndexByKey,
  getUserFlow,
  isErrorOnPaymentGeneric,
  organizeFlowsAppend,
  organizePaymentError,
  seriealizeAllSteps,
  getTagging,
  getTrackError,
} from './checkout.utils';
import { OTP_FLOW, PAYMENT_FLOW, PAYMENT_METHOD_FLOW, PORTABILITY_FLOW } from './checkout.flow';
import { ID_FLOWS } from './checkout.constants';
import { useCustomerAsyncInformation } from './hooks/UseCustomerAsyncInformation/useCustomerAsyncInformation';
import { useCustomerDeliveryBase } from './hooks/UseCustomerDeliveryBase/useCustomerDeliveryBase';
import { useCustomerDeliveryAdvance } from './hooks/UseCustomerDeliveryAdvance/useCustomerDeliveryAdvance';
import { useCartAsyncInformation } from './hooks/UseCartAsyncInformation/useCartAsyncInformation';
import { useCheckoutErrors } from './hooks/UseCheckoutErrors/useCheckoutErrors';
import { useDeliveryBillingFlow } from './hooks/UseDeliveryBillingFlow/useDeliveryBillingFlow';
import { useDeliveryLocateAndPickupPoint } from './hooks/UseDeliveryLocateAndPickupPoint/useDeliveryLocateAndPickupPoint';
import { useAuthorizationAsyncInformation } from './hooks/UseAuthorizationAsyncInformation/useAuthorizationAsyncInformation';
import { useCustomerFlow } from './hooks/UseCustomerFlow/useCustomerFlow';
import { useVoucherDiscount } from './hooks/UseVoucherDiscount/useVoucherDiscount';
import { useCookieDeleteCart } from './hooks/UseCookieDeleteCart/useCookieDeleteCart';
import { usePortabilityFlow } from './hooks/UsePortabilityFlow/usePortabilityFlow';
import { useForceCampaignFlow } from './hooks/UseForceCampaignFlow/useForceCampaignFlow';
import DebugSIACard from './components/DebugSIACard/debugSIACard';
import PreCheckout from './components/PreCheckout/preCheckout';
import { useCheckout } from '../iBuyMobile.context';
import { ENABLE_DEBUG } from '../iBuyMobile.utils';
import { useUpdateUserFlow } from './hooks/UseUpdateUserFlow/useUpdateUserFlow';
import { useOnlyEsimFlow } from './hooks/UseOnlyEsimFlow/useOnlyEsimFlow';
import { useAddonFlowMobile } from './hooks/UseAddonFlowMobile/useAddonFlowMobile';
import { ProductDetailExtended } from '@vfit/consumer/components';

const Checkout = ({
  handleClose,
  backgroundImage,
  isLoadingCart,
  isErrorOnPaymentShowErrorModal,
  hideCards,
}: ICheckoutModalChildrenProps) => {
  const {
    owningData,
    isLastCard,
    findCaller,
    slidesGoBack,
    isDisabledBackButton,
    portability,
    currentStepKey,
    hideStickyBar,
    availablePaymentMethods,
    billingAddress,
    shippingAddress,
    billingEmail,
    isNewShippingAddress,
    updateProductPriceCtrl,
    isValidNumberPortability,
    isPreselectedCard,
    pickupLocationStore,
    customerFlow,
    configuredStickyStepperLabel,
    isModalButtonSticky,
    checkoutErrors,
    product,
    addons,
    addonState,
    setPickupLocationStore,
    setBillingReplace,
    setSlidesGoBack,
    initContext,
    setOfferId,
    setShippingAddress,
    setBillingAddress,
    checkInitContext,
    deleteSerializedContext,
    serializeContext,
    setIsLoadingCart,
    setIsNewShippingAddress,
    setRecharge,
    setIsModalButtonSticky,
    setAddonState,
    isLoadingGoNext,
  } = useCheckout();
  const queryClient = useQueryClient();
  const stepperRef = useRef<IStepperUtils>();
  const [checkoutSIA, setCheckoutSIA] = useState<ICheckoutSIA>({
    isShow: false,
  });
  const IS_DISABLED_REDIRECT = localStorage.getItem('disableRed');
  const isWinBack = product?.isWinback;
  const isWinBackLegacy = product?.isWinbackLegacy;
  const voucherId = getParamsInUrl('voucherId');
  const isRequiredPortability = product?.isRequiredPortability;
  const silentLogin: any = queryClient.getQueryData('silentLogin');
  const isLoggedUser = LOGGED_USER_KEYS.includes(silentLogin?.flowId) || false;
  const flow = checkIsGoBackSIA() ? deSeriealizeAllSteps(product) : getUserFlow(product);
  const [steps, setSteps] = useState<IStepCard[]>(flow);
  const [showPageLoader, setShowPageLoader] = useState<boolean>(checkIsGoBackSIA());
  const [hideInternalComponents, setHideInternalComponents] = useState<boolean>(checkIsGoBackSIA());
  const [alreadySetShippingAndBilling, setAlreadySetShippingAndBilling] = useState<boolean>(false);

  const globalConfig = useCmsConfig(
    CONSUMER_CMS_SHOPPING_CART,
    API.CMS_GET_GLOBAL_CONFIG
  ) as IGlobalConfigCMS;
  const commonLabels = useCmsConfig(
    CONSUMER_CMS_SHOPPING_CART,
    API.CMS_GET_STEPPER_COMMON_LABELS_MOBILE
  ) as ICommonLabelsInStepper;

  const detailsPageCMS = useCmsConfig(
    CONSUMER_CMS_SHOPPING_CART,
    API.CMS_GET_DETAILS_PAGE_MOBILE
  ) as IDetailsPageCMS;

  const { closeProductDetails, openProductDetails, goBackInStepper } =
    organizeCommonLabelsInStepper(commonLabels);

  const isButtonStickyConfig = globalConfig?.globalconfig?.modals?.enableButtonSticky === 'true';
  setIsModalButtonSticky(isButtonStickyConfig);

  // region Hooks API

  const { payMean } = usePayMeanData();
  const {
    startDeliveryLocateAndPickupPoint,
    global: globalPPOrPO,
    searchPickupLocations,
    searchLocateStore,
    searchPostalOffice,
  } = useDeliveryLocateAndPickupPoint();

  useCustomerDeliveryAdvance();

  useCustomerAsyncInformation();

  useCustomerDeliveryBase();

  useCartAsyncInformation();

  useAuthorizationAsyncInformation();

  useCustomerFlow();

  useForceCampaignFlow();

  useVoucherDiscount();

  useCookieDeleteCart();

  usePortabilityFlow();

  useUpdateUserFlow();

  useOnlyEsimFlow();

  useAddonFlowMobile();

  const {
    createAddress: createBillingAddress,
    validateAddress,
    replaceAddress: replaceBillingAddress,
    billingPaper,
    billingEbill,
  } = useDeliveryBillingFlow({
    billingAddress: billingAddress as unknown as IValidAddressList,
    enableBillDelivery: customerFlow?.billingAccount?.isSuccess,
    billingEmail,
  });

  useEffect(() => {
    setPickupLocationStore({
      ...pickupLocationStore,
      startDeliveryLocateAndPickupPoint,
      global: { ...globalPPOrPO },
      searchPostalOffice: { ...searchPostalOffice },
      searchLocateStore: { ...searchLocateStore },
      searchPickupLocations: { ...searchPickupLocations },
    });
  }, [globalPPOrPO, searchPickupLocations, searchPostalOffice]);

  useEffect(() => {
    setBillingReplace({
      createAddress: {
        ...createBillingAddress,
      },
      validateAddress: {
        ...validateAddress,
      },
      replaceAddress: {
        ...replaceBillingAddress,
      },
      billingPaper: {
        ...billingPaper,
      },
      billingEbill: {
        ...billingEbill,
      },
    });
  }, [createBillingAddress, validateAddress, replaceBillingAddress, billingEbill, billingPaper]);

  useEffect(() => {
    if (!isNewShippingAddress) setIsNewShippingAddress(true);
  }, [shippingAddress]);

  useCheckoutErrors({ handleClose, replaceBillingAddress, createBillingAddress });

  // endregion

  // region Organize CMS
  const dataFromCms = useCmsConfig(
    CONSUMER_CMS_SHOPPING_CART,
    API.CMS_GET_PAYMENTS_MOBILE
  ) as IPayments;
  const errorMobileList = useCmsConfig(
    CONSUMER_CMS_SHOPPING_CART,
    API.CMS_GET_RECURRENT_ORDER_ERROR_MOBILE
  ) as ICMSApiError;
  const supportModulesCms = useCmsConfig(
    CONSUMER_CMS_SHOPPING_CART,
    API.CMS_GET_SUPPORT_MODULE
  ) as ISupportModule;
  const genericError = useCmsConfig(
    CONSUMER_CMS_SHOPPING_CART,
    API.CMS_GET_GENERIC_ERROR
  ) as IGenericErrorCMS;

  const siaError = errorMobileList?.errorcomponent?.errors?.find(
    (item: ICMSError) => item?.error === ErrorCodes.ERROR_ON_PAYMENT_GENERIC
  );

  const { messageErrorPayment, actionTextErrorPayment, titleErrorPayment } =
    organizePaymentError(dataFromCms);

  const { urlPaymentErrorCallMeNow } = productSpecificError(product as IProduct);

  // endregion

  // region Append steps

  /**
   * On remove cards, this method update the stepper's active index
   * @param organizedStep
   */
  const updateActiveIndexOnRemoveCards = (organizedStep: IStepCard[]) => {
    const diffSteps = steps.length - organizedStep.length;
    if (diffSteps > 0 && stepperRef.current) {
      const diff = stepperRef.current._getActiveSlideIndex() - diffSteps;
      stepperRef.current._setActiveSlideIndex(diff < 0 ? 0 : diff);
    }
  };

  const appendPortabilitySteps = () => {
    if (!checkIsGoBackSIA() && !(isWinBack || isWinBackLegacy)) {
      if (!portability?.portabilityWant) {
        const organizedStepPortabilityOtp = organizeFlowsAppend(
          steps,
          false,
          ID_FLOWS.PORTABILITY_NUMBER,
          PORTABILITY_FLOW.PORTABILITY_OTP
        );
        const organizedStepPortabilityWant = organizeFlowsAppend(
          organizedStepPortabilityOtp,
          false,
          ID_FLOWS.PORTABILITY,
          PORTABILITY_FLOW.PORTABILITY_NUMBER
        );
        setSteps(organizedStepPortabilityWant);
      } else {
        let organizedStepPortabilityWant = steps;
        const flowPortabilityNumber = !isRequiredPortability
          ? ID_FLOWS.PORTABILITY_NUMBER
          : ID_FLOWS.PORTABILITY_NUMBER_PORTABILITY_REQUIRED;
        if (!isRequiredPortability) {
          organizedStepPortabilityWant = organizeFlowsAppend(
            steps,
            portability?.portabilityWant || false,
            ID_FLOWS.PORTABILITY,
            PORTABILITY_FLOW.PORTABILITY_NUMBER
          );
        }
        const organizedStepPortabilityOtp = organizeFlowsAppend(
          organizedStepPortabilityWant,
          !isValidNumberPortability && portability?.portabilityWant === true,
          flowPortabilityNumber,
          PORTABILITY_FLOW.PORTABILITY_OTP
        );
        setSteps(organizedStepPortabilityOtp);
        if (isValidNumberPortability) updateActiveIndexOnRemoveCards(organizedStepPortabilityOtp);
      }
    }
  };

  const appendOtpAndPaymentSteps = () => {
    if (!checkIsGoBackSIA()) {
      const paymentData = getLastPaymentMethod(payMean as IPayMeanServiceResponse);
      const appendCardForRecurrent =
        !!payMean &&
        !!paymentData &&
        !product?.isTpoOffer &&
        findCaller.customerHasActiveSubs &&
        getCurrentUserType() !== IUserType.NEXT_USER_INACTIVE;
      if (paymentData) {
        localStorage.setItem(SELECTED_PAYMENT, paymentData.type);
      }
      let organizedSteps = steps;
      const fromKeySteps =
        isWinBack || isWinBackLegacy ? ID_FLOWS.PERSONAL_INFO_WINBACK : ID_FLOWS.PERSONAL_INFO;
      if (!findCaller.showOtp || AppMobile.checkIsApp()) {
        organizedSteps = organizeFlowsAppend(steps, false, fromKeySteps, OTP_FLOW.OTP);
      } else {
        organizedSteps = organizeFlowsAppend(
          steps,
          findCaller.showOtp || false,
          fromKeySteps,
          OTP_FLOW.OTP
        );
      }
      if (!product?.enableVoucher) {
        organizedSteps = organizeFlowsAppend(
          organizedSteps,
          appendCardForRecurrent,
          ID_FLOWS.CONSENTS,
          PAYMENT_METHOD_FLOW.PAYMENT_METHOD
        );
      } else {
        organizedSteps = organizeFlowsAppend(
          organizedSteps,
          appendCardForRecurrent,
          ID_FLOWS.VOUCHER,
          PAYMENT_METHOD_FLOW.PAYMENT_METHOD
        );
      }
      setSteps(organizedSteps);
      updateActiveIndexOnRemoveCards(organizedSteps);
    }
  };

  // endregion

  const setShippingBillingForLoggedOrActive = (customer: GetCustomerResponse) => {
    if (!customer) return;
    const postalAddress = getShippingForLoggedOrActive(customer);
    const bAddress = getBillingForLoggedOrActive(customer);
    setShippingAddress(postalAddress as unknown as IAddressDataObject);
    setBillingAddress(bAddress as unknown as IAddressDataObject);
  };

  /**
   * Method to go back programmatically from context
   */
  const programmaticallyGoBackNext = async (
    numberSlides: number,
    type: 'NEXT' | 'BACK',
    onFinish?: () => void
  ) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const delay = (time: number) => new Promise((resolve) => setTimeout(resolve, time));
    if (numberSlides !== -1) {
      // eslint-disable-next-line no-restricted-syntax
      for (const indexSlides of Array.from(Array(numberSlides - 1))) {
        LoggerInstance.debug('IndexSlides', indexSlides);
        if (type === 'NEXT') {
          stepperRef?.current?._stepperGoNext();
          await delay(100);
        } else {
          stepperRef?.current?._stepperGoPrev();
          await delay(600);
        }
      }
      // setSlidesGoBack(-1);
      setTimeout(() => {
        if (onFinish) {
          stepperRef?.current?._setActiveSlideIndex(numberSlides + 1);
          onFinish?.();
        }
      }, 150 * numberSlides);
    }
  };

  /**
   * Method to manage checkout errors
   */
  const trackErrorCheckout = () => {
    const authrorizationError = getFromLocalStorageByKey(ErrorCodes.AUTHORIZATION_ERROR_KO);
    const errorObj = authrorizationError
      ? getTrackError(authrorizationError, 'authorization', queryClient)
      : {};
    const taggingProduct = getTagging('', 'mobile', queryClient);
    const pageName = `${retrieveBasePageName(
      taggingProduct?.trackingProduct,
      taggingProduct?.trackingProduct?.cart_product_name
    )}`;
    tracking(
      {
        ...errorObj,
        event_name: ['page_error'],
        event_category: 'error',
        page_name: `${pageName}:page_error`,
        page_type: 'error_page',
        product_name: taggingProduct?.pageProductsInfo.product_name,
      },
      'view'
    );
  };

  /**
   * Method to manage errors on back SIA
   */
  const manageErrorsGoBackSIA = () => {
    let onClose = () => {
      LoggerInstance.debug('onclose');
    };
    let actionEvent = () => {
      LoggerInstance.debug('action');
    };
    let errorSpecificPaymentTitle = localStorage.getItem(PAYMENT_ERROR_TITLE);
    let errorSpecificPaymentMessage = localStorage.getItem(PAYMENT_ERROR_MESSAGE);
    let errorSpecificPaymentShowCtc = localStorage.getItem(PAYMENT_SHOW_CTC) === 'true';
    if (isErrorOnPaymentGeneric()) {
      errorSpecificPaymentTitle = errorSpecificPaymentTitle || siaError?.title || 'Ops';
      errorSpecificPaymentMessage =
        errorSpecificPaymentMessage || siaError?.message || 'Si è verificato un errore';
      errorSpecificPaymentShowCtc = errorSpecificPaymentShowCtc || true;
      onClose = () => {
        deleteSerializedContext();
        window.history.pushState(null, '', window.location.pathname);
        handleClose();
      };
      actionEvent = () => window.history.pushState(null, '', window.location.pathname);
    }
    trackErrorCheckout();
    errorManager.handleError(ErrorService.getSeverityErrorMedium(), {
      disableTrack: true,
      title: errorSpecificPaymentTitle || titleErrorPayment,
      message: errorSpecificPaymentMessage || messageErrorPayment,
      onClose,
      actionEvent,
      actionText: actionTextErrorPayment,
      isButtonSticky: isModalButtonSticky,
      ...(errorSpecificPaymentShowCtc && {
        secondActionEvent: () => openPopup(urlPaymentErrorCallMeNow),
        secondButtonText: '',
      }),
      opts: {
        product_name: product?.slug || '',
      },
    });
  };

  const checkBackSIA = () => {
    if (!checkIsGoBackSIA() || !checkInitContext()) return;
    initContext();
    addRemoveVisibilityCards('hide');
    if (isErrorOnPaymentShowErrorModal) {
      manageErrorsGoBackSIA();
    }
    programmaticallyGoBackNext(
      getSlideIndexByKey(steps, getActiveSlideFromStorage()),
      'NEXT',
      () => {
        setShowPageLoader(false);
        addRemoveVisibilityCards('show');
        if (checkIsGoBackSIA()) {
          setTimeout(() => {
            stepperRef?.current?._stepperGoPrev();
            window.history.pushState(null, '', window.location.pathname);
            setHideInternalComponents(false);
          }, 200);
        }
      }
    );
  };

  const handleRedirect = () => {
    seriealizeAllSteps(steps);
    serializeContext();
    localStorage.removeItem(FLOW_TYPE);
    resetData(queryClient, ['authorization', 'authorizationError']);
    switch (currentStepKey) {
      case ID_FLOWS.PAYMENT_METHOD:
      case ID_FLOWS.RECURRENT_PAYMENT_CARD:
        localStorage.setItem(FLOW_TYPE, ISIAFlowType.RECURRENT.toString());
        setCheckoutSIA({
          isShow: true,
        });
        break;
      case ID_FLOWS.UPFRONT_PAYMENT:
        localStorage.setItem(FLOW_TYPE, ISIAFlowType.INSTANT.toString());
        setCheckoutSIA({
          isShow: true,
        });
        break;
      default:
        break;
    }
    LoggerInstance.debug('End flow');
  };

  const setAutomaticRecharge = () => {
    setRecharge({ option: 'Yes' });
    localStorage.setItem(IS_AUTOMATIC_RECHARGE_SELECTED, '1');
  };

  const manageSilentLoginError = (event: Event) => {
    const { detail } = event as CustomEvent as { detail: ITrackError };
    if (detail) {
      checkoutErrors?.showAndTrackError?.(
        genericError,
        () => {
          window.location.href = '/';
        },
        detail
      );
    }
  };

  useLayoutEffect(() => {
    if (hideCards) {
      addRemoveVisibilityCards('hide');
    }
  }, []);

  useEffect(() => {
    setIsLoadingCart(!!isLoadingCart);
    if (!isLoadingCart) {
      checkBackSIA();
      const customerData: GetCustomerResponse = getFromLocalStorageByKey('customerData')?.[0];
      if (customerData) setShippingBillingForLoggedOrActive(customerData);
    }
  }, [isLoadingCart]);

  useEffect(() => {
    if (isLoggedUser && !owningData.isLogged && !isLoadingCart) {
      const customerData = getFromLocalStorageByKey('customerData')?.[0];
      if (customerData) {
        const countries: ICountryObject[] = getFromLocalStorageByKey('getCountry');
        initContext({ customerData, countries, isLogged: isLoggedUser });
        setTimeout(() => {
          setShippingBillingForLoggedOrActive(customerData);
        }, 100);
      }
    }
  }, [silentLogin, isLoggedUser, isLoadingCart]);

  useEffect(() => {
    if (availablePaymentMethods && !checkIsGoBackSIA()) {
      const notWallet = availablePaymentMethods?.find(
        (availablePayment) =>
          availablePayment?.configured && availablePayment?.methodCode !== EMethodCode.WALLET
      );
      if (notWallet || isPreselectedCard) {
        const organizedStepPaymentWithoutWallet = organizeFlowsAppend(
          steps,
          false,
          ID_FLOWS.RECURRENT_PAYMENT_CARD,
          PAYMENT_FLOW.WARNING_PRICE_CHANGE
        );
        setSteps(organizedStepPaymentWithoutWallet);
      } else {
        const organizedStepPaymentWithWallet = organizeFlowsAppend(
          steps,
          updateProductPriceCtrl.isUpdatePrice && !updateProductPriceCtrl.isPriceUpdated,
          ID_FLOWS.RECURRENT_PAYMENT_CARD,
          PAYMENT_FLOW.WARNING_PRICE_CHANGE
        );
        setSteps(organizedStepPaymentWithWallet);
        updateActiveIndexOnRemoveCards(organizedStepPaymentWithWallet);
      }
    }
  }, [updateProductPriceCtrl, availablePaymentMethods, isPreselectedCard]);

  useEffect(() => {
    appendOtpAndPaymentSteps();
  }, [findCaller, payMean]);

  useEffect(() => {
    appendPortabilitySteps();
  }, [portability, isValidNumberPortability]);

  useEffect(() => {
    const closeOnEscapeKey = (e: KeyboardEvent) => (e.key === 'Escape' ? handleClose() : null);
    document.body.addEventListener('keydown', closeOnEscapeKey);
    return () => {
      document.body.removeEventListener('keydown', closeOnEscapeKey);
    };
  }, [handleClose]);

  useEffect(() => {
    if (slidesGoBack && slidesGoBack !== -1 && stepperRef?.current) {
      programmaticallyGoBackNext(slidesGoBack, 'BACK');
      setSlidesGoBack(-1);
    }
  }, [slidesGoBack]);

  useEffect(() => {
    const customerData: GetCustomerResponse = getFromLocalStorageByKey('customerData')?.[0];
    setOfferId(product?.offerId?.toString() || '');
    setAutomaticRecharge();
    if (voucherId) {
      localStorage.setItem('voucherId', voucherId);
      window.history.pushState(null, '', window.location.pathname);
    }
    if (customerData && !isLoggedUser && !alreadySetShippingAndBilling) {
      setAlreadySetShippingAndBilling(true);
      setShippingBillingForLoggedOrActive(customerData);
    }
    document.addEventListener(ALL_EVENTS.SILENT_LOGIN_ERROR, manageSilentLoginError);
    return () => {
      document.removeEventListener(ALL_EVENTS.SILENT_LOGIN_ERROR, manageSilentLoginError);
    };
  }, []);

  const productDetailComponent =
    addons && addons.length > 0 ? (
      <ProductDetailExtended
        product={product}
        addons={addons}
        detailsPageCMS={detailsPageCMS}
        flowType="mobile"
        addonState={addonState}
        setAddonState={setAddonState}
        supportModule={supportModulesCms}
      />
    ) : (
      <ProductDetail
        address=""
        trackingOpt={{
          event: ['view'],
          event_label: 'offer details',
          opts: {
            product_target_segment: 'consumer',
            page_section: 'fixed offers',
            page_subsection: 'checkout',
          },
          cartProduct: {
            cart_product_id: `${product?.offerId}`,
            cart_product_name: product?.slug,
            cart_product_category: 'fixed',
            cart_product_price: product?.price && removeCurrency(product.price),
            cart_product_quantity: '1',
          },
          visitorTrackingOpts: getUserInfo(),
          disableInitialTrack: false,
        }}
        modalInfo={{
          title: supportModulesCms?.supportmodules?.needHelp?.title || '',
          ctaName: supportModulesCms?.supportmodules?.needHelp?.ctaSupportCall || '',
        }}
        details={{
          title: product.title,
          conditions: product.conditions,
          callMeNowHelp: product.callMeNowHelp,
          description: product.description,
          offerDetails: product.offerDetails,
          price: product.price,
          originalPrice: product.originalPrice,
          recurrence: product.recurrence,
          voucherPromoLabel: product?.voucherPromoLabel,
          voucherPromoMessage: product?.voucherPromoMessage,
          voucherPromoDetail: product?.voucherPromoDetail,
        }}
      />
    );

  const productToShow = product ? productDetailComponent : <div />;

  const getTitleStickyStepper = () => {
    if (!product?.recurrence) return product?.price;
    return `${product?.price}<span class="rec">/${product?.recurrence}</span>`;
  };

  return (
    <>
      <OverlayGlobalStyle />
      <CheckoutModalContainer>
        <StepperStateCards
          ref={stepperRef}
          steps={steps}
          onBack={handleClose}
          onClose={handleClose}
          onFinish={handleRedirect}
          enableStickyStepper={!hideStickyBar}
          topLabelStickyStepper={product?.topLabelStickyCheckout || ''}
          titleStickyStepper={getTitleStickyStepper()}
          actionLabelStickyStepper={{ openProductDetails, closeProductDetails }}
          contentStickyStepper={productToShow}
          blurredImage={backgroundImage}
          forceFinish={isLastCard || false}
          hideBackButton={isDisabledBackButton}
          isLoadingContainer={!silentLogin || isLoggedUser ? isLoadingCart : false}
          isLoadingStickyOffer={isLoadingCart}
          isLoadingGoNext={isLoadingGoNext}
          hideInternalComponents={hideInternalComponents}
          lottieLoader={product?.loaders?.coverageTool}
          nextLabelStickyStepper={configuredStickyStepperLabel || ''}
          variantNextLabelStickyStepper={
            // eslint-disable-next-line no-nested-ternary
            configuredStickyStepperLabel ? (checkIsApp() ? 'mva:alt1' : 'secondary') : undefined
          }
          goBackLabel={goBackInStepper}
        />
        {showPageLoader && (
          <LoadingPage>
            <LoadingSpinner />
          </LoadingPage>
        )}
      </CheckoutModalContainer>
      {!ENABLE_DEBUG && !IS_DISABLED_REDIRECT && checkoutSIA.isShow && <PreCheckout />}
      {ENABLE_DEBUG && checkoutSIA?.isShow && <DebugSIACard />}
    </>
  );
};

export default Checkout;
