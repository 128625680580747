import { API, INextError, nextClient } from '@vfit/shared/data-access';
import { useQuery, useQueryClient } from 'react-query';
import { errorMock, IGetMobileAddonResponse } from '@vfit/consumer/data-access';
import { IGetAddonResponse } from './getAddon.models';

const getAddonMobileService = (cartItemId: string, cartId): Promise<IGetAddonResponse> =>
  nextClient.get(`${API.MOBILE_OFFERING_CONFIGURATION}/${cartItemId}`, {
    searchParams: {
      salesChannel: 'selfService',
      context: `shoppingCart.Id==${cartId}`,
      configurationType: 'fullBasicForCatalog',
      qualificationCriteria: 'orderingActivity==newSubscription',
      levelOfData:
        'bundledAddOnConfiguration, bundledAddOnGroupConfiguration, planConfiguration, price, simConfiguration, oldSimConfiguration,mobileOfferingConfiguration',
      portInIndicator: false,
    },
  });

export const useGetAddonsMobile = (cartItemId: string, cartId: string, queryEnabler = false) => {
  const queryClient = useQueryClient();
  return useQuery(['getAvailableAddons'], () => getAddonMobileService(cartItemId, cartId), {
    enabled: queryEnabler,
    onSuccess: (data: IGetMobileAddonResponse) => {
      queryClient.setQueryData('getAvailableAddons', data);
    },
    onError: (error: INextError) => {
      queryClient.setQueryData('getAvailableAddons', errorMock('getAvailableAddons', error));
    },
  });
};
