import {API, PAGES, trackLink} from '@vfit/shared/data-access';
import { CMS_CONFIG, IAddonCommonsCMS, useCmsConfig } from '@vfit/consumer/data-access';
import { organizeaddonsBlock } from './ModalConfirmRemoveAddOn.organize';
import { IModalConfirmRemoveAddOn } from './ModalConfirmRemoveAddOn.models';

const useModalConfirmRemoveAddOn = ({
  setIsOpenModalSureDeleteAddOn,
  handleRemoveAction,
  addonCmsId,
}: IModalConfirmRemoveAddOn) => {
  //
  const cmsData = useCmsConfig(
    CMS_CONFIG[PAGES.CONSUMER],
    API.CMS_GET_ADDONS_COMMON
  ) as IAddonCommonsCMS;

  const {
    addoncommons: { removePopup },
  } = organizeaddonsBlock(cmsData);

  const handleRemoveAddOn = () => {
    setIsOpenModalSureDeleteAddOn(false);
    handleRemoveAction(addonCmsId || '');
  };

  const handleClosePopup = () => {
    trackLink(
      removePopup.continueCtaLabel || 'prosegui',
      "users",
      "click",
      "ui_interaction"
    );
    setIsOpenModalSureDeleteAddOn(false);
  }

  return { handleRemoveAddOn, handleClosePopup, removePopup };
};

export { useModalConfirmRemoveAddOn };
