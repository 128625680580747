import { useEffect } from 'react';
import {
  checkIsGoBackSIA,
  CONSUMER_CMS_SHOPPING_CART,
  errorMock,
  IOptionsCMS,
  IPersonalInfoCmsMobile,
  IShoppingCartResponse,
  useCmsConfig,
  useSimTypeSelectionService,
} from '@vfit/consumer/data-access';
import { API, getFromLocalStorageByKey } from '@vfit/shared/data-access';
import { IFormat } from '@vfit/shared/models';
import { useCheckout } from '../../../iBuyMobile.context';

export const useCartAsyncInformation = () => {
  const {
    isLoadingCart,
    setSimOptions,
    setCartAsyncInfo,
    cartAsyncInfo,
    setSimTypeSelectedOption,
  } = useCheckout();
  const simDataFromCms = useCmsConfig(
    CONSUMER_CMS_SHOPPING_CART,
    API.CMS_GET_PERSONAL_DATA_MOBILE
  ) as IPersonalInfoCmsMobile;
  const {
    mutate: mutateSim,
    isError: isErrorSim,
    isLoading: isLoadingSim,
    isSuccess: isSuccessSim,
    data: dataSim,
    error: dataSimError,
  } = useSimTypeSelectionService();

  // region SIM TYPE SELECTION

  const updateSimOptions = () => {
    const simOptionsCms: IOptionsCMS[] = simDataFromCms?.personalinfomobile?.simType?.choice;
    const simProduct = dataSim?.orderItems?.find(
      (orderItem) => orderItem?.product?.type?.toLowerCase() === 'mobile'
    );
    const allSimOptions = simProduct?.product?.childProduct?.[0]?.format || [];

    const isExistPreflaggedFromCms = simOptionsCms?.some((sO) => sO?.isPreflagged === 'true');

    const getAmdPreflagged = (value: string) => {
      return (
        allSimOptions?.find((e) => {
          return e?.name?.trim()?.toLowerCase() === value?.trim()?.toLowerCase();
        })?.selection || false
      );
    };

    const getOptionValue = (simOption: IOptionsCMS) => {
      if (!simOption?.value) {
        if (simOption?.title?.toLowerCase()?.includes('esim')) return 'eSim NEXT Fully Digital';
        return 'Physical SIM';
      }
      return simOption?.value;
    };

    const simOptionsData = {
      simOptions:
        simOptionsCms.map((simOption) => {
          const value = getOptionValue(simOption);
          return {
            value,
            title: simOption?.title,
            description:
              allSimOptions?.find(
                (sim) => sim?.name?.trim()?.toLowerCase() === value?.trim()?.toLowerCase()
              )?.description || '',
            id:
              allSimOptions?.find(
                (sim) => sim?.name?.trim()?.toLowerCase() === value?.trim()?.toLowerCase()
              )?.id || '',
            selection: isExistPreflaggedFromCms
              ? simOption?.isPreflagged === 'true'
              : getAmdPreflagged(getOptionValue(simOption)),
            name: allSimOptions?.find((sim) => sim.name === value)?.name || '',
            highlight: simOption?.isHighlighted === 'true',
          };
        }) || [],
    };

    setSimOptions(simOptionsData);
    const selectedOption: IFormat | undefined = simOptionsData?.simOptions?.find(
      (el) => el.selection
    );

    if (selectedOption) {
      setSimTypeSelectedOption(selectedOption);
    }
  };

  useEffect(() => {
    if (isSuccessSim && dataSim) {
      updateSimOptions();
    }
  }, [dataSim, isSuccessSim]);

  useEffect(() => {
    if (isLoadingSim) {
      setCartAsyncInfo({
        ...cartAsyncInfo,
        simTypeSelection: {
          error: undefined,
          isLoading: true,
          isError: false,
          isSuccess: false,
        },
      });
    } else if (isErrorSim) {
      setCartAsyncInfo({
        ...cartAsyncInfo,
        simTypeSelection: {
          error: errorMock('simSelection', dataSimError),
          isLoading: false,
          isError: true,
          isSuccess: false,
        },
      });
    } else if (isSuccessSim) {
      setCartAsyncInfo({
        ...cartAsyncInfo,
        simTypeSelection: {
          error: undefined,
          isLoading: false,
          isError: false,
          isSuccess: true,
        },
      });
    }
  }, [isErrorSim, isLoadingSim, isSuccessSim]);

  useEffect(() => {
    if (!isLoadingCart && !checkIsGoBackSIA()) {
      const shoppingCart: IShoppingCartResponse = getFromLocalStorageByKey('shoppingCart');
      const simSelection = getFromLocalStorageByKey('simSelection');
      if (!simSelection && shoppingCart?.id) {
        mutateSim({ cartId: shoppingCart.id });
      }
    }
  }, [isLoadingCart]);

  // endregion

  return null;
};
