import { breakpoints, colors } from '@vfit/shared/themes';
import styled from 'styled-components';

const ConfirmDeleteAddOn = styled.section`
  display: flex;
  flex-direction: column;
  height: 82vh;

  @media (min-width: ${breakpoints.tablet}) {
    padding: 70px 0 64px;
    align-items: center;
    max-height: 423px;
    justify-content: center;
  }

  @media (min-width: ${breakpoints.desktop}) {
    min-width: 842px;
    max-height: 442px;
  }
`;

const Content = styled.main`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-grow: 1;

  @media (min-width: ${breakpoints.tablet}) {
    flex-grow: 0;
    flex-shrink: 0;
  }
`;

const ButtonsBox = styled.footer`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 34px 33px;
  flex-shrink: 0;

  button:first-of-type {
    margin-bottom: 15px;
  }

  @media (min-width: ${breakpoints.tablet}) {
    width: 258px;
    margin: 0 auto;
  }
`;

const Title = styled.h1`
  color: ${colors.$262626};
  text-align: center;
  font-family: 'Vodafone ExB', sans-serif;
  font-size: 30px;
  font-style: normal;
  font-weight: 400;
  line-height: 38px;
  margin: 16px 0 8px;
  max-width: 256px;
`;

const Desription = styled.div`
  color: ${colors.$262626};
  text-align: center;
  font-family: 'Vodafone Rg', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  max-width: 256px;
  margin: 0;

  p {
    margin: 0;
  }

  @media (min-width: ${breakpoints.tablet}) {
    margin: 0 0 24px;
  }

  @media (min-width: ${breakpoints.desktop}) {
    max-width: none;
    margin: 0 0 40px;
  }
`;

export { Content, Title, ConfirmDeleteAddOn, Desription, ButtonsBox };
