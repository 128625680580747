import {
  IAvailablePaymentMethods,
  IPaymentServiceResponse,
  IPersonalDataObject,
  IValidateAddressResponse,
} from '@vfit/shared/models';
import {
  ErrorCodes,
  getCustomerCountry,
  getCustomerDocument,
  getCustomerIdentification,
  IGlobalConfigCMS,
} from '@vfit/consumer/data-access';
import { getFromLocalStorageByKey } from '@vfit/shared/data-access';

const setDateFormat = (date: string) => {
  const day = date.substring(0, 2);
  const month = date.substring(3, 5);
  const year = date.substring(6);
  return year.concat('-', month, '-', day);
};

const getErrorStatusText = (status: string, errorCode?: string): string => {
  if (status == '409') {
    return ErrorCodes.CUSTOMER_ALREADY_PRESENT;
  }
  if (status == '400') {
    return errorCode || '';
  }
  return errorCode || '';
};

const getPatchPayload = (oData: IPersonalDataObject) => {
  const { contactMedium } = oData?.owningIndividual || {};
  const identificationInfo = getCustomerIdentification(oData.owningIndividual);
  const selectedCountry = getCustomerCountry(oData);
  const selectedDocument = getCustomerDocument(oData);
  const validatedAddressResponse: IValidateAddressResponse =
    getFromLocalStorageByKey('validatedAddress');
  const address = validatedAddressResponse?.validAddressList?.[0];

  const replaceAddress = () => [
    { type: 'formattedAddress1', operation: 'remove', index: 2 },
    { type: 'formattedAddress3', operation: 'remove', index: 2 },
    { updatedValue: address?.city || '', type: 'city', operation: 'replace', index: 2 },
    {
      updatedValue: address?.postalCode || '',
      type: 'postalCode',
      operation: 'replace',
      index: 2,
    },
    {
      updatedValue: address?.streetNumber || '',
      type: 'streetNumber',
      operation: 'replace',
      index: 2,
    },
    {
      updatedValue: address?.externalId || '',
      type: 'externalId',
      operation: 'replace',
      index: 2,
    },
    {
      updatedValue: address?.stateOrProvince || '',
      type: 'stateOrProvince',
      operation: 'replace',
      index: 2,
    },
    { updatedValue: address?.street || '', type: 'street', operation: 'replace', index: 2 },
    {
      updatedValue: address?.istatCode || '',
      type: 'istatCode',
      operation: 'replace',
      index: 2,
    },
    { updatedValue: address?.homeZone || '', type: 'homeZone', operation: 'replace', index: 2 },
    { updatedValue: address?.latitude || '', type: 'latitude', operation: 'replace', index: 2 },
    {
      updatedValue: address?.longitude || '',
      type: 'longitude',
      operation: 'replace',
      index: 2,
    },
  ];

  const replaceContact = () => [
    {
      updatedValue: selectedDocument?.[0]?.id,
      type: 'identificationType',
      operation: 'replace',
      index: 0,
    },
    {
      updatedValue: identificationInfo?.identificationNumber,
      type: 'identificationNumber',
      operation: 'replace',
      index: 0,
    },
    {
      updatedValue: setDateFormat(identificationInfo?.expirationDate || ''),
      type: 'expirationDate',
      operation: 'replace',
      index: 0,
    },
    {
      updatedValue: selectedCountry?.[0]?.id || '',
      type: 'nationality',
      operation: 'replace',
      index: 0,
    },
    {
      updatedValue: contactMedium?.[0]?.emailAddress || '',
      type: 'email',
      operation: 'replace',
      index: 0,
    },
    {
      updatedValue: contactMedium?.[1]?.phoneNumber || '',
      type: 'number',
      operation: 'replace',
      index: 1,
    },
  ];

  if (!address) return replaceContact();
  return [...replaceContact(), ...replaceAddress()];
};

/**
 * ONLY IN FIRST PERIOD BS SIMULATION 24/11
 * this method return billingAccountData without new payments if is not in BS
 * @param billingAccountData
 * @param cmsGlobalConfig
 */
const getBSNewMopMobile = (
  paymentData: IPaymentServiceResponse,
  cmsGlobalConfig: IGlobalConfigCMS
): IPaymentServiceResponse | undefined => {
  const customerData = getFromLocalStorageByKey('customerData');
  const findCaller = getFromLocalStorageByKey('findCaller');
  const customerDataCF =
    customerData?.[0]?.owningIndividual?.fiscalCode || findCaller?.individualRef?.fiscalCode;
  const isCustomerXBS = customerDataCF?.includes('XBS');
  const defaultPayments = ['creditCard', 'bankAccount', 'wallet'];
  const isGlobalBsNewMopMobile =
    cmsGlobalConfig?.globalconfig?.newMop?.enableNewMopMobile === 'true';

  if (isCustomerXBS || isGlobalBsNewMopMobile) return paymentData;
  const availablePaymentMethods =
    paymentData?.orderPayment?.orderOnBillPayment?.availablePaymentMethods;

  // HERE TO REDUCE THE AVAILABLE METHODS TO THE OLD ONES
  const oldAvailablePaymentMethods = availablePaymentMethods?.filter((el) =>
    defaultPayments?.includes(el?.methodCode)
  ) as [IAvailablePaymentMethods];

  const reducedPaymentData: IPaymentServiceResponse = {
    ...paymentData,
    orderPayment: {
      ...paymentData?.orderPayment,
      orderOnBillPayment: {
        ...paymentData?.orderPayment?.orderOnBillPayment,
        availablePaymentMethods: oldAvailablePaymentMethods,
      },
    },
  };

  return reducedPaymentData;
};

export { getPatchPayload, getErrorStatusText, getBSNewMopMobile };

