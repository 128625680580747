import {useEffect, useState} from 'react';
import { VfModal } from '@vfit/shared/components';
import { IAddonDetailsCMS } from '@vfit/consumer/data-access';
import { ButtonSlide, CustomText, Svg } from '@vfit/shared/atoms';
import { purify } from '@vfit/shared/themes';
import {trackLink, trackView} from "@vfit/shared/data-access";
import * as S from '../../productDetailExtended.style';
import { IAddonsBlock } from '../../productDetailExtended.models';
import { evaluateAddonDetails } from '../../productDetailExtended.utils';
import { ModalConfirmRemoveAddOn } from './ModalConfirmRemoveAddOn/ModalConfirmRemoveAddOn';

export const AddonsBlock = ({ addonList, isLoadingUpdate, removeAddon }: IAddonsBlock) => {
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [addonElement, setAddonElement] = useState<IAddonDetailsCMS | undefined>(undefined);
  const { summary, detailPopup, common } = evaluateAddonDetails();
  const [isOpenModalSureDeleteAddOn, setIsOpenModalSureDeleteAddOn] = useState(false);

  const isLastElement = <T,>(list: T[], index: number): boolean => list.length - 1 === index;
  const renderModal = (addon: IAddonDetailsCMS) => {
    trackLink('Scopri tutti i dettagli') // todo: add label
    setAddonElement(addon);
    setIsOpenModal(true);
  };

  const handleRemoveClick = (addon: IAddonDetailsCMS) => {
    trackLink(
      "rimuovi",
      "users",
      "click",
      "cart_remove"
      )
    setAddonElement(addon);
    setIsOpenModalSureDeleteAddOn(true);
  }

  const purifyText = (text: string) => {
    const [pText] = purify([text]);
    return pText;
  };

  const handleRemoveAction = (_addonId: string) => {
    if (isOpenModal) setIsOpenModal(false);
    removeAddon(_addonId);
  };

  useEffect(() => {
    if(isOpenModal){
      trackView({
        event_name: 'view',
        event_label_track: 'addons:detail',
      })
    }
  }, [isOpenModal])


  return (
    <>
      <S.Block>
        {addonList &&
          addonList.map((addon, index) => (
            <>
              <S.SubBlock>
                <S.HeaderWithIcon>
                  <S.BigText>{addon.addondetails?.name}</S.BigText>
                  {!isLoadingUpdate &&
                    <div style={{cursor: 'pointer'}}>
                    <Svg
                      name="trashCan"
                      height={24}
                      width={24}
                      onClick={() => handleRemoveClick(addon)}
                    />
                  </div>}
                </S.HeaderWithIcon>
                <S.DetailsContainer>
                  <S.BoldLabel>{summary(addon)?.topPrice}</S.BoldLabel>
                  <S.Price>
                    {common(addon)?.priceRecurring}€/{common(addon)?.recurringLabel}
                  </S.Price>
                  <div>{common(addon)?.priceActivationLabel}</div>{' '}
                  <S.AddonDescription
                    dangerouslySetInnerHTML={{
                      __html: purifyText(summary(addon)?.description || '') || '',
                    }}
                  />
                </S.DetailsContainer>
                <S.DetailsCta onClick={() => renderModal(addon)}>
                  Scopri tutti i dettagli
                </S.DetailsCta>
              </S.SubBlock>
              {!isLastElement(addonList, index) && <S.SeparationLine />}
            </>
          ))}
      </S.Block>

      {/** Details Popup * */}
      <VfModal isOpen={isOpenModal} handleClose={() => setIsOpenModal(false)}>
        <>
          <h1>{detailPopup(addonElement)?.popupTitle}</h1>
          <CustomText
            text={detailPopup(addonElement)?.popupDescription || ''}
            textAlign="left"
            size={18}
            sizeMobile={20}
            lineHeight={24}
            lineHeightMobile={26}
          />
          <S.CtaContainer>
            <ButtonSlide
              label={common(addonElement)?.ctaRemoveLabel}
              onClick={() => handleRemoveAction(addonElement?.cmsId || '')}
              buttonColor="#fff"
              labelColor="#262626"
              borderColor="#262626"
            />
            <ButtonSlide
              label={common(addonElement)?.ctaCloseLabel}
              onClick={() => setIsOpenModal(false)}
              buttonColor="#fff"
              labelColor="#262626"
              borderColor="#262626"
            />
          </S.CtaContainer>
        </>
      </VfModal>

      {/** Remove Popup * */}
      <VfModal
        isOpen={isOpenModalSureDeleteAddOn}
        handleClose={() => setIsOpenModalSureDeleteAddOn(false)}
        isModalFullContainer
      >
        <ModalConfirmRemoveAddOn
          setIsOpenModalSureDeleteAddOn={setIsOpenModalSureDeleteAddOn}
          handleRemoveAction={handleRemoveAction}
          addonCmsId={addonElement?.cmsId}
        />
      </VfModal>
    </>
  );
};
