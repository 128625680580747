import { useMutation, useQueryClient } from 'react-query';
import { CustomOptions, INextError, LoggerInstance, nextClient } from '@vfit/shared/data-access';
import { API, getFromLocalStorageByKey } from '@vfit/shared/data-access';
import {
  ErrorCodes,
  errorMock,
  IAPIError,
  IMultiPlayOfferingConfigurationResponse,
  IShoppingCartResponse,
} from '@vfit/consumer/data-access';
import { IAddressResponse, IPortabilityObject } from '@vfit/shared/models';
import { retrieveProvider } from './portabilityNumber.utils';
import {
  ICheckPortInFeasibilityIIMutationPayload,
  ICheckPortInFeasibilityIIPayload,
  ICheckPortInFeasibilityIIResponse,
  IPortInDetailsMobile,
} from './checkPortInFeasibilityII.models';

/**
 * API service to validate the migration code
 * @param payload
 * @param customOptions
 */
export const checkPortInFeasibilityIIService = (
  payload: ICheckPortInFeasibilityIIPayload,
  customOptions?: CustomOptions
): Promise<ICheckPortInFeasibilityIIResponse> =>
  nextClient.post(API.CHECK_IN_PORT, payload, {
    ...(customOptions?.headers && { headers: customOptions.headers }),
    ...(customOptions?.silentLoginHeaders && {
      silentLoginHeaders: customOptions.silentLoginHeaders,
    }),
  });

/**
 * Mutation for saving into the key 'checkPortInFeasibilityIIQuery'
 * the response coming from the API service useCheckPortInFeasibilityII
 * to validate the migration code
 * @returns
 * @deprecated
 */
export const useCheckPortInFeasibilityII = () => {
  const queryClient = useQueryClient();

  return useMutation(
    'checkPortInFeasibilityII',
    (data: ICheckPortInFeasibilityIIMutationPayload) => {
      const { values, providers } = data;

      // Get createdAddress obj from localstorage
      const createdAddressSaved = localStorage.getItem('createdAddress');
      const createdAddress: IAddressResponse =
        !!createdAddressSaved && JSON.parse(createdAddressSaved as string);

      const shoppingCartSaved = localStorage.getItem('shoppingCart');
      const shoppingCart: IShoppingCartResponse =
        !!shoppingCartSaved &&
        shoppingCartSaved !== 'undefined' &&
        JSON.parse(shoppingCartSaved as string);

      const fixedVoiceOrderActionId = shoppingCart?.cartItem?.[0]?.fixedVoice?.[0]?.id;

      const fixedVoiceEgonAddressId = createdAddress?.externalId;
      const fixedVoiceIstatCode = createdAddress?.istatCode;

      const customerSaved = localStorage.getItem('customerData');
      const customerData =
        !!customerSaved && customerSaved !== 'undefined' && JSON.parse(customerSaved as string);

      let firstName: string;
      let lastName: string;
      let fiscalCode: string;

      if (customerData?.[0]?.owningIndividual) {
        firstName = customerData?.[0]?.owningIndividual?.firstName;
        lastName = customerData?.[0]?.owningIndividual?.lastName;
        fiscalCode = customerData?.[0]?.owningIndividual?.fiscalCode;
      }

      const multiPlayOfferingConfigurationSaved = localStorage.getItem(
        'multiPlayOfferingConfiguration'
      );
      const multiPlayOfferingConfiguration: IMultiPlayOfferingConfigurationResponse | undefined =
        !!multiPlayOfferingConfigurationSaved &&
        multiPlayOfferingConfigurationSaved !== 'undefined' &&
        JSON.parse(multiPlayOfferingConfigurationSaved as string);

      const firstServiceProviders =
        !!multiPlayOfferingConfiguration &&
        multiPlayOfferingConfiguration?.fixedVoicePortInDetails?.[0]
          .r1MissingMigrationFixedPortInSpecification?.r1FirstDonatingNetworkOperator
          ?.availableValues;
      const secondServiceProviders =
        !!multiPlayOfferingConfiguration &&
        multiPlayOfferingConfiguration?.fixedVoicePortInDetails?.[0]
          .r1MissingMigrationFixedPortInSpecification?.r1SecondDonatingNetworkOperator
          ?.availableValues;

      const firstProvider =
        firstServiceProviders &&
        retrieveProvider(firstServiceProviders, providers.firstSelectedProvider);
      const secondProvider =
        secondServiceProviders &&
        retrieveProvider(secondServiceProviders, providers.secondSelectedProvider);

      const payload: ICheckPortInFeasibilityIIPayload = {
        portInDetails: [
          {
            portInDetailsFixed: {
              ...values,
              firstDonatingServiceProviderId: firstProvider,
              firstDonatingServiceProvider: firstProvider,
              ...(values?.hasInternetService &&
                providers?.secondSelectedProvider && {
                  secondDonatingServiceProviderId: secondProvider,
                  secondDonatingServiceProvider: secondProvider,
                }),
            },
            fixedVoiceOrderActionId,
            fixedVoiceEgonAddressId,
            fixedVoiceIstatCode,
            flowType: 'Fixed',
            orderActionId: fixedVoiceOrderActionId,
            customerInfo: {
              firstName,
              lastName,
              fiscalCode,
            },
          },
        ],
      };

      localStorage.setItem('checkPortInFeasibilityIIPayload', JSON.stringify(payload));
      queryClient.setQueryData('checkPortInFeasibilityIIPayload', payload);

      return checkPortInFeasibilityIIService(payload);
    },
    {
      onSuccess: (data: ICheckPortInFeasibilityIIResponse) => {
        // TODO Check if "resultDescription": "Failure" return error
        if (data?.resultDescription?.toLowerCase() === 'failure') {
          let errorMessage: string;
          if (data?.resultMessages?.[0]) {
            if (data?.resultMessages?.[0]?.messageCode === '9000054') {
              errorMessage = ErrorCodes.MIGRATION_CODE_ERROR;
            } else if (data?.resultMessages?.[0]?.messageCode === '9000352') {
              errorMessage = ErrorCodes.MIGRATION_CODE_ERROR;
            } else if (data?.resultMessages?.[0]?.messageDescription) {
              errorMessage = ErrorCodes.NUMBER_ERROR;
            } else {
              // not mapped error on checkPortInFeasibility if "resultDescription": "Failure"
              errorMessage = ErrorCodes.CHECK_IN_PORT_FEASIBILITY_GENERIC_ERROR;
            }
            const error = errorMock(
              'checkPortInFeasibilityII',
              {
                status: '200',
                statusText: '200',
                url: API.CHECK_IN_PORT,
              },
              errorMessage
            );
            queryClient.setQueryData('checkPortInFeasibilityIIQuery', error);
            localStorage.setItem('checkPortInFeasibilityIIQuery', JSON.stringify(error));
          }

          const error = errorMock(
            'checkPortInFeasibilityIIQuery',
            {
              status: '200',
              statusText: '200',
              url: API.CHECK_IN_PORT,
            },
            errorMessage
          );
          queryClient.setQueryData('checkPortInFeasibilityIIQuery', error);
          localStorage.setItem('checkPortInFeasibilityIIQuery', JSON.stringify(error));
        } else {
          // Success save checkPortInFeasibilityIIQuery object into storage
          localStorage.setItem('checkPortInFeasibilityIIQuery', JSON.stringify(data));

          queryClient.setQueryData('checkPortInFeasibilityIIQuery', data);
        }
      },
      onError: (error: INextError) => {
        LoggerInstance.error(`ERROR - checkPortInFeasibilityIIQuery: `, error);
        queryClient.setQueryData(
          'checkPortInFeasibilityIIQuery',
          errorMock(`checkPortInFeasibilityII`)
        );
        localStorage.setItem(
          `checkPortInFeasibilityIIQuery`,
          JSON.stringify(errorMock(`checkPortInFeasibilityII`))
        );
      },
    }
  );
};

export const useCheckPortInFeasibilityIIFixed = () => {
  const queryClient = useQueryClient();

  return useMutation(
    'checkPortInFeasibilityII',
    ({
      isMultipleOperator,
      hasInternetService,
      portabilityNumber,
      portabilityMigration,
    }: IPortabilityObject) => {
      const createdAddress = getFromLocalStorageByKey('createdAddress');
      const customerData = getFromLocalStorageByKey('customerData');
      const shoppingCart: IShoppingCartResponse = getFromLocalStorageByKey('shoppingCart');
      const multiPlayOfferingConfiguration: IMultiPlayOfferingConfigurationResponse =
        getFromLocalStorageByKey('multiPlayOfferingConfiguration');

      const fixedVoiceOrderActionId = shoppingCart?.cartItem?.[0]?.fixedVoice?.[0]?.id;
      const fixedVoiceEgonAddressId = createdAddress?.externalId;
      const fixedVoiceIstatCode = createdAddress?.istatCode;
      const firstName = customerData?.[0]?.owningIndividual?.firstName || '';
      const lastName = customerData?.[0]?.owningIndividual?.lastName || '';
      const fiscalCode = customerData?.[0]?.owningIndividual?.fiscalCode || '';
      const firstServiceProviders =
        multiPlayOfferingConfiguration?.fixedVoicePortInDetails?.[0]
          ?.r1MissingMigrationFixedPortInSpecification?.r1FirstDonatingNetworkOperator
          ?.availableValues;
      const secondServiceProviders =
        multiPlayOfferingConfiguration?.fixedVoicePortInDetails?.[0]
          ?.r1MissingMigrationFixedPortInSpecification?.r1SecondDonatingNetworkOperator
          ?.availableValues;
      // This values is not used
      // const isMultipleOperator =
      //   multiPlayOfferingConfiguration?.fixedVoicePortInDetails?.[0]?.r1MissingMigrationFixedPortInSpecification?.r1VoiceAndInternetDifferentProvider?.toLowerCase() ===
      //     'yes' || false;
      // const hasInternetService =
      //   multiPlayOfferingConfiguration?.fixedVoicePortInDetails?.[0]
      //     ?.r1MissingMigrationFixedPortInSpecification?.r1IsInternetIndicator === 'Yes';
      const providers = {
        firstSelectedProvider: portabilityMigration?.firstOperator,
        // eslint-disable-next-line no-nested-ternary
        ...(hasInternetService && isMultipleOperator
          ? { secondSelectedProvider: portabilityMigration?.secondOperator }
          : hasInternetService && !isMultipleOperator
          ? { secondSelectedProvider: portabilityMigration?.firstOperator }
          : {}),
      };

      const firstProvider =
        firstServiceProviders &&
        retrieveProvider(firstServiceProviders, providers.firstSelectedProvider);

      const secondProvider =
        secondServiceProviders &&
        retrieveProvider(secondServiceProviders, providers.secondSelectedProvider);

      const payload: ICheckPortInFeasibilityIIPayload = {
        portInDetails: [
          {
            portInDetailsFixed: {
              cli: portabilityNumber,
              hasInternetService: hasInternetService ? 'Yes' : 'No',
              firstMigrationCode: portabilityMigration?.firstCode,
              ...(isMultipleOperator && {
                secondMigrationCode: portabilityMigration?.secondCode,
              }),
              firstDonatingServiceProviderId: firstProvider,
              firstDonatingServiceProvider: firstProvider,
              ...(hasInternetService &&
                providers?.secondSelectedProvider && {
                  secondDonatingServiceProviderId: secondProvider,
                  secondDonatingServiceProvider: secondProvider,
                }),
            },
            fixedVoiceOrderActionId,
            fixedVoiceEgonAddressId,
            fixedVoiceIstatCode,
            flowType: 'Fixed',
            orderActionId: fixedVoiceOrderActionId,
            customerInfo: {
              firstName,
              lastName,
              fiscalCode,
            },
          },
        ],
      };
      return checkPortInFeasibilityIIService(payload);
    },
    {
      onSuccess: (data: ICheckPortInFeasibilityIIResponse) => {
        if (data?.resultDescription?.toLowerCase() === 'failure') {
          let errorMessage: string;
          if (data?.resultMessages?.[0]) {
            if (data?.resultMessages?.[0]?.messageCode === '9000054') {
              errorMessage = ErrorCodes.MIGRATION_CODE_ERROR;
            } else if (data?.resultMessages?.[0]?.messageCode === '9000352') {
              errorMessage = ErrorCodes.MIGRATION_CODE_ERROR;
            } else if (data?.resultMessages?.[0]?.messageDescription) {
              errorMessage = ErrorCodes.NUMBER_ERROR;
            } else {
              // not mapped error on checkPortInFeasibility if "resultDescription": "Failure"
              errorMessage = ErrorCodes.CHECK_IN_PORT_FEASIBILITY_GENERIC_ERROR;
            }
            const error = errorMock(
              'checkPortInFeasibilityII',
              {
                status: '200',
                statusText: '200',
                url: API.CHECK_IN_PORT,
              },
              errorMessage
            );
            queryClient.setQueryData('checkPortInFeasibilityIIQuery', error);
            localStorage.setItem('checkPortInFeasibilityIIQuery', JSON.stringify(error));
          }
          const error = errorMock(
            'checkPortInFeasibilityIIQuery',
            {
              status: '200',
              statusText: '200',
              url: API.CHECK_IN_PORT,
            },
            errorMessage
          );
          queryClient.setQueryData('checkPortInFeasibilityIIQuery', error);
          localStorage.setItem('checkPortInFeasibilityIIQuery', JSON.stringify(error));
        } else {
          // Success save checkPortInFeasibilityIIQuery object into storage
          localStorage.setItem('checkPortInFeasibilityIIQuery', JSON.stringify(data));
          queryClient.setQueryData('checkPortInFeasibilityIIQuery', data);
        }
      },
      onError: (error: INextError) => {
        LoggerInstance.error(`ERROR - checkPortInFeasibilityIIQuery: `, error);
        queryClient.setQueryData(
          'checkPortInFeasibilityIIQuery',
          errorMock(`checkPortInFeasibilityII`)
        );
        localStorage.setItem(
          `checkPortInFeasibilityIIQuery`,
          JSON.stringify(errorMock(`checkPortInFeasibilityII`))
        );
      },
    }
  );
};

export const useCheckPortInFeasibilityIIMobile = () => {
  const queryClient = useQueryClient();

  return useMutation(
    'checkPortInFeasibilityII',
    ({
      msisdn,
      isSimAvailable,
      simCardNumber,
      currentPlanType,
      currentServiceProviderId,
      currentServiceProviderIdName,
    }: IPortInDetailsMobile) => {
      const shoppingCart: IShoppingCartResponse = getFromLocalStorageByKey('shoppingCart');
      const customerData = getFromLocalStorageByKey('customerData');

      let firstName: string;
      let lastName: string;
      let fiscalCode: string;

      if (customerData?.[0]?.owningIndividual) {
        firstName = customerData?.[0]?.owningIndividual?.firstName;
        lastName = customerData?.[0]?.owningIndividual?.lastName;
        fiscalCode = customerData?.[0]?.owningIndividual?.fiscalCode;
      }

      const payload: ICheckPortInFeasibilityIIPayload = {
        portInDetails: [
          {
            orderActionId: shoppingCart?.cartItem?.[0]?.id,
            msisdn,
            isSimAvailable,
            simCardNumber: simCardNumber || '',
            currentPlanType,
            currentServiceProviderId,
            currentServiceProviderIdName,
            flowType: 'Mobile',
            customerInfo: {
              firstName,
              lastName,
              fiscalCode,
            },
          },
        ],
      };

      localStorage.setItem('checkPortInFeasibilityIIPayload', JSON.stringify(payload));
      queryClient.setQueryData('checkPortInFeasibilityIIPayload', payload);

      return checkPortInFeasibilityIIService(payload);
    },
    {
      onSuccess: (data: ICheckPortInFeasibilityIIResponse | IAPIError) => {
        // TODO Check if "resultDescription": "Failure" return error
        if (
          (data as ICheckPortInFeasibilityIIResponse)?.resultDescription?.toLowerCase() ===
          'failure'
        ) {
          let errorMessage: string;
          if ((data as ICheckPortInFeasibilityIIResponse)?.resultMessages?.[0]) {
            if (
              (data as ICheckPortInFeasibilityIIResponse)?.resultMessages?.[0]?.messageCode ===
              '9000054'
            ) {
              errorMessage = ErrorCodes.MIGRATION_CODE_ERROR;
            } else if (
              (data as ICheckPortInFeasibilityIIResponse)?.resultMessages?.[0]?.messageDescription
            ) {
              errorMessage = ErrorCodes.NUMBER_ERROR;
            } else {
              // not mapped error on checkPortInFeasibility if "resultDescription": "Failure"
              errorMessage = ErrorCodes.CHECK_IN_PORT_FEASIBILITY_GENERIC_ERROR;
            }
            const error = errorMock(
              'checkPortInFeasibilityIIQuery',
              {
                status: '200',
                statusText: '200',
                url: API.CHECK_IN_PORT,
              },
              errorMessage
            );
            queryClient.setQueryData('checkPortInFeasibilityIIQuery', error);
            localStorage.setItem('checkPortInFeasibilityIIQuery', JSON.stringify(error));
          }

          const error = errorMock(
            'checkPortInFeasibilityIIQuery',
            {
              status: '200',
              statusText: '200',
              url: API.CHECK_IN_PORT,
            },
            errorMessage
          );
          queryClient.setQueryData('checkPortInFeasibilityIIQuery', error);
          localStorage.setItem('checkPortInFeasibilityIIQuery', JSON.stringify(error));
        } else {
          // Success save checkPortInFeasibilityIIQuery object into storage
          localStorage.setItem('checkPortInFeasibilityIIQuery', JSON.stringify(data));

          queryClient.setQueryData('checkPortInFeasibilityIIQuery', data);
        }
      },
      onError: (error: INextError) => {
        LoggerInstance.error(`ERROR - checkPortInFeasibilityIIQuery: `, error);
        queryClient.setQueryData(
          'checkPortInFeasibilityIIQuery',
          errorMock(`checkPortInFeasibilityII`, error)
        );
        localStorage.setItem(
          `checkPortInFeasibilityIIQuery`,
          JSON.stringify(errorMock(`checkPortInFeasibilityII`, error))
        );
      },
    }
  );
};

