import { IAddonCommonsCMS } from '@vfit/consumer/data-access';
import { purify } from '@vfit/shared/themes';

const organizeaddonsBlock = (cmsData: IAddonCommonsCMS) => ({
  addoncommons: {
    addonStep: {
      skipCtaLabel: cmsData?.addoncommons?.addonStep?.skipCtaLabel || '',
      topText: cmsData?.addoncommons?.addonStep?.topText || '',
    },
    language: cmsData?.addoncommons?.language || '',
    removePopup: {
      continueCtaLabel: cmsData?.addoncommons?.removePopup?.continueCtaLabel || '',
      description: Array.isArray(cmsData?.addoncommons?.removePopup?.description)
        ? purify(cmsData.addoncommons.removePopup.description as string[]).join(' ')
        : cmsData?.addoncommons?.removePopup?.description || '',
      icon: cmsData?.addoncommons?.removePopup?.icon || '',
      removeCtaLabel: cmsData?.addoncommons?.removePopup?.removeCtaLabel || '',
      title: cmsData?.addoncommons?.removePopup?.title || '',
    },
  },
});

export { organizeaddonsBlock };
