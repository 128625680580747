import {
  usePatchCustomer,
  ErrorCodes,
  GetCustomerResponse,
  getCustomerIdentification,
  errorMock,
} from '@vfit/consumer/data-access';
import { useEffect } from 'react';
import { LoggerInstance } from '@vfit/shared/data-access';
import { getFromLocalStorageByKey } from '@vfit/shared/data-access';
import { ICountryObject } from '@vfit/shared/models';
import { useCheckout } from '../../../iBuyMobile.context';
import { getErrorStatusText, getPatchPayload } from '../UseCustomerFlow/useCustomerFlow.utils';
import { ID_FLOWS } from '../../checkout.constants';
import { handleCRMErrorCodes, retrieveOwningIndividual } from '../../checkout.utils';

export const useUpdateUserFlow = () => {
  const { product, currentStepKey, customerFlow, owningData, setCustomerFlow } = useCheckout();
  const {
    mutate: patchCustomerMutate,
    data: patchCustomer,
    isError: isErrorPatchCustomer,
    isSuccess: isSuccessPatchCustomer,
    error: patchCustomerError,
  } = usePatchCustomer();

  const checkPatchCustomer = (): boolean => {
    const countries: ICountryObject[] = getFromLocalStorageByKey('getCountry');
    const customerData: GetCustomerResponse[] = getFromLocalStorageByKey('customerData');
    if (!customerData || !countries) return false;
    const retrivedIndividual = retrieveOwningIndividual(
      customerData?.[0],
      countries as ICountryObject[]
    );
    const retrievedIdentification = getCustomerIdentification(retrivedIndividual);
    const owningDataIdentification = getCustomerIdentification(owningData.owningIndividual);
    const owningDataContactMedium = owningData?.owningIndividual?.contactMedium || [];
    const retrievedCustomerDataContactMedium =
      customerData?.[0]?.owningIndividual?.contactMedium || [];
    const owningDataEmail = owningDataContactMedium?.[0]?.emailAddress || '';
    const retrievedCustomerDataEmail = retrievedCustomerDataContactMedium?.[0]?.emailAddress || '';
    const owningDataPhoneNumber = owningDataContactMedium?.[1]?.phoneNumber || '';
    const retrievedCustomerDataPhoneNumber =
      retrievedCustomerDataContactMedium?.[1]?.phoneNumber || '';
    if (!retrievedIdentification?.identificationNumber || !retrievedIdentification.expirationDate)
      return false;
    return (
      retrievedIdentification?.identificationType !==
        owningDataIdentification?.identificationType ||
      retrievedIdentification?.identificationNumber !==
        owningDataIdentification?.identificationNumber ||
      retrievedIdentification?.expirationDate !== owningDataIdentification?.expirationDate ||
      retrievedIdentification?.nationality !== owningDataIdentification?.nationality ||
      retrievedCustomerDataPhoneNumber !== owningDataPhoneNumber ||
      retrievedCustomerDataEmail !== owningDataEmail
    );
  };

  useEffect(() => {
    if (isSuccessPatchCustomer && patchCustomer) {
      setCustomerFlow({
        ...customerFlow,
        patchCustomer: {
          ...customerFlow.patchCustomer,
          data: patchCustomer,
          isLoading: false,
          isSuccess: true,
          isError: false,
          error: undefined,
        },
        customerInfo: {
          ...customerFlow.customerInfo,
          data: patchCustomer?.[0],
          isLoading: false,
          isSuccess: true,
          isError: false,
          error: undefined,
        },
      });
      localStorage.setItem('customerData', JSON.stringify([patchCustomer]));
    }
  }, [isSuccessPatchCustomer]);

  useEffect(() => {
    if (isErrorPatchCustomer) {
      const headerErrorCode = patchCustomerError?.headers?.get('ERR-errorCode') || '';
      const backendError = patchCustomerError?.headers?.get('ERR-backendErrorCode') || '';
      const backendMessage = patchCustomerError.headers?.get('ERR-backendErrorMessage') || '';
      const errorCode =
        headerErrorCode === ErrorCodes.DIGITAL_CRM_CODE
          ? handleCRMErrorCodes(backendError)
          : headerErrorCode;
      LoggerInstance.log('PatchCustomer', headerErrorCode, backendError, backendMessage, errorCode);
      setCustomerFlow({
        ...customerFlow,
        patchCustomer: {
          ...customerFlow.patchCustomer,
          isLoading: false,
          isSuccess: false,
          isError: true,
          error: errorMock(
            'patchCustomer',
            {
              status: patchCustomerError.status,
              statusText: getErrorStatusText(patchCustomerError.status, errorCode),
              url: '',
            },
            backendError,
            errorCode,
            backendMessage
          ),
        },
      });
    }
  }, [isErrorPatchCustomer]);

  useEffect(() => {
    const checkStep =
      currentStepKey === ID_FLOWS.PORTABILITY ||
      (product?.isWinback && currentStepKey === ID_FLOWS.PORTABILITY_NUMBER) ||
      (product?.isRequiredPortability && currentStepKey === ID_FLOWS.PORTABILITY_NUMBER) ||
      (product?.skipMnp &&
        !product.isEsimOnly &&
        currentStepKey === ID_FLOWS.RECOGNITION_ESIM_SKIP_MNP) ||
      (product?.skipMnp &&
        product.isEsimOnly &&
        currentStepKey === ID_FLOWS.RECOGNITION_CARD_SKIP_MNP);
    if (checkPatchCustomer() && checkStep) {
      patchCustomerMutate(getPatchPayload(owningData));
    }
  }, [owningData, currentStepKey]);

  return null;
};
